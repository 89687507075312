import type { SimpleApiResult, SimpleResult } from '@/shared/types';
import { isFailApiResult, isSimpleResult } from '@/shared/types';
import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import type { ErrorsResponse } from '../models';
import { isErrorsResponse } from '../models';

export function getMessagesFromError(error: ErrorsResponse | Error | null | undefined) {
    if (!error) return [];
    if (error instanceof Error && !isErrorsResponse(error)) return [error.message];

    if (error.errors.length) {
        return error.errors.map((error) => error.message);
    }

    if (error.fieldErrors) {
        const fieldErrors = error.fieldErrors;
        const keys = Object.keys(fieldErrors);

        return keys.map((key: string) => {
            return fieldErrors[key].message;
        });
    }

    return [];
}

export function useApiErrors(errorsResponse: Ref<ErrorsResponse | Error | null> | Ref<undefined>) {
    const errorMessages = computed(() => {
        return getMessagesFromError(errorsResponse.value);
    });

    const hasErrors = computed(() => {
        return errorMessages.value.length > 0;
    });

    return {
        errorMessages,
        hasErrors,
    };
}

export function useApiResponseErrors(
    apiResult: Ref<SimpleResult | SimpleApiResult | ErrorsResponse | Error | null> | Ref<undefined>,
) {
    const duplicateErrorCount = ref(0);

    const errorMessages = computed(() => {
        // const result = toValue(apiResult);

        if (!apiResult.value) return [];
        if (apiResult.value instanceof Error) return getMessagesFromError(apiResult.value);
        if (isErrorsResponse(apiResult.value)) return getMessagesFromError(apiResult.value);

        if (apiResult.value.success) return [];

        if (isFailApiResult(apiResult.value)) {
            return getMessagesFromError(apiResult.value.error);
            // if (apiResult.value.error.errors.length) {
            //     return apiResult.value.error.errors.map((error) => error.message);
            // }

            // if (apiResult.value.error.fieldErrors) {
            //     const fieldErrors = apiResult.value.error.fieldErrors;
            //     const keys = Object.keys(fieldErrors);

            //     return keys.map((key: string) => {
            //         return fieldErrors[key].message;
            //     });
            // }
        }

        if (isSimpleResult(apiResult.value) && apiResult.value.message) {
            return [apiResult.value.message];
        }

        return ['An error has occurred. Please try again later.'];
    });

    const hasResponseError = computed(() => {
        return errorMessages.value.length > 0;
    });

    watch(errorMessages, (newErrors, oldErrors) => {
        if (!newErrors || !oldErrors) return;

        if (JSON.stringify(newErrors) === JSON.stringify(oldErrors)) {
            duplicateErrorCount.value++;
        } else {
            duplicateErrorCount.value = 0;
        }
    });

    return {
        errorMessages,
        hasResponseError,
        duplicateErrorCount,
    };
}

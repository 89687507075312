import type { MaybeRef } from 'vue';
import { toValue } from 'vue';
import { useQuery } from 'vue-query';
import type { UseQueryOptions } from 'vue-query';
import type { EndpointResponse, QueryEndpoint } from '../endpoints';
import type { ErrorsResponse } from '../models/errorsResponse';
import { callApiUnsafe, callApiWithoutAuthUnsafe } from '../utilities';

type Unwrap<T> = T extends MaybeRef<infer U> ? U : T;

export function useApi<
    TQueryKey extends MaybeRef<QueryEndpoint | [QueryEndpoint, ...unknown[]]>,
    TError = ErrorsResponse,
    UnWrappedQueryKey extends Unwrap<TQueryKey> = Unwrap<TQueryKey>,
    TEndpoint extends QueryEndpoint = UnWrappedQueryKey extends QueryEndpoint ? UnWrappedQueryKey
        : UnWrappedQueryKey[0],
    TQueryFnData = EndpointResponse[TEndpoint],
    TData = EndpointResponse[TEndpoint],
>(
    queryKey: TQueryKey,
    fetcher: (endpoint: TEndpoint, apiFetcher: typeof callApiUnsafe<TEndpoint>) => Promise<TQueryFnData>,
    options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, Unwrap<TQueryKey>>, 'queryKey' | 'queryFn'> & {
        skipAuth?: boolean;
    },
) {
    const allOptions = options || {};

    // Get the endpoint from either the key array, or the single string
    const endpoint = Array.isArray(toValue(queryKey)) ? toValue(queryKey)[0] : toValue(queryKey) as string as TEndpoint;

    // Tanstack query expects an array as the key, so we need to convert it
    const arrayifiedKey = Array.isArray(toValue(queryKey)) ? queryKey : [queryKey]; // We intentionally pass MaybeRef into vue-query to allow for reactivity

    const apiCaller = options?.skipAuth ? callApiWithoutAuthUnsafe : callApiUnsafe;

    return useQuery({
        ...allOptions,
        // @ts-expect-error Typings are all funky... idk why
        queryKey: arrayifiedKey,
        queryFn: async () => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
            return await fetcher(endpoint as any, apiCaller) as Promise<TQueryFnData>; // as any hack to work around funkyness...
        },
    });
}

// example
// const {} = useApi('auth/REFRESH_TOKEN', async (endpoint, apiFetcher) => {
//     return apiFetcher(endpoint, {
//        token,
//        refreshToken
//     })
// });

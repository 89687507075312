export type CompanyResource = { dataKey: string | null; };
export type CreatedByResource = { createdById: string; };

export type ResourceEntityArg = CompanyResource & CreatedByResource;

export interface ResourceConstraintOptionSameCompany {
    /** Restricts the capability to the same company that owns the resource.
     * If that company is an Organization then access is expanded to clients (sub companies)
     */
    sameCompany: boolean;
}

export interface ResourceConstraintOptionCreatedBy {
    /** Restricts the capability to just the user who created the resource */
    createdBy: boolean;
}

export type ResourceConstraintOption = ResourceConstraintOptionSameCompany & ResourceConstraintOptionCreatedBy;

export type ResourceConstraintOptions =
    | ResourceConstraintOptionSameCompany
    | ResourceConstraintOptionCreatedBy
    | ResourceConstraintOption
    | Partial<ResourceConstraintOption>;

export type ResourceArg<TOptions extends ResourceConstraintOptions> = TOptions extends
    ResourceConstraintOptionSameCompany & ResourceConstraintOptionCreatedBy ? ResourceEntityArg
    : TOptions extends ResourceConstraintOptionCreatedBy ? CreatedByResource
    : TOptions extends ResourceConstraintOptionSameCompany ? CompanyResource
    : Partial<ResourceEntityArg>;

export function isCompanyConstraint(
    opts: ResourceConstraintOptions,
): opts is ResourceConstraintOptionSameCompany {
    return (opts as ResourceConstraintOptionSameCompany).sameCompany !== undefined;
}

export function isCreatedByConstraint(
    opts: ResourceConstraintOptions,
): opts is ResourceConstraintOptionCreatedBy {
    return (opts as ResourceConstraintOptionCreatedBy).createdBy !== undefined;
}

/* eslint-disable @typescript-eslint/ban-types */

export function debounce(fn: Function, wait = 250, immediate = false) {
    let timeout: number | undefined;

    const debounced = (...args: unknown[]) => {
        const later = () => {
            timeout = undefined;
            if (immediate !== true) {
                fn(...args);
            }
        };

        clearTimeout(timeout);
        if (immediate === true && timeout === undefined) {
            fn(...args);
        }
        timeout = window.setTimeout(later, wait);
    };

    debounced.cancel = () => {
        clearTimeout(timeout);
    };

    return debounced;
}

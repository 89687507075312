export async function getBlobBase64Buffer(blob: Blob, size: number = 256) {
    const sizeToRead = Math.min(blob.size, size);
    const slice = blob.slice(0, sizeToRead);

    const base64String = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = function() {
            if (!reader.result) reject('Error reading the blob, blob is empty.');

            // Extract the base64 part from the result
            console.log('reader result: ', reader.result!);
            const base64 = (reader.result! as string).split(',')[1];
            resolve(base64);
        };
        reader.onerror = () => reject('Error reading the blob.');

        // Read the slice as a Data URL
        reader.readAsDataURL(slice);
    });

    return base64String as string;
}

import type { EndpointDefinitions } from '@/features/api';
import type {
    EditOrderRequest,
    FilterOrdersRequest,
    OrderResponse,
    OrderStatus,
    OrderStatusChangeResponse,
    SimpleOrderResponse,
} from '@/features/campaigns/api';
import { API_BASE } from '@/shared';
import type { OrderStatusChangeRequest } from './ordersApiRequestModels';
import type { OrderAllowedStateChanges, OrderStatusChangeKindsMap } from './ordersApiResponseModels';

export type OrdersEndpointDefinitions = {
    GET_ORDER_ALLOWED_STATES: {
        request: never;
        response: OrderAllowedStateChanges;
        kind: 'query';
    };
    GET_ORDER_STATUS_CHANGE_KINDS: {
        request: never;
        response: OrderStatusChangeKindsMap;
        kind: 'query';
    };
    GET_ORDER_TERMINAL_STATUSES: {
        request: never;
        response: OrderStatus[];
        kind: 'query';
    };
    GET_ORDER: {
        request: never;
        response: OrderResponse;
        kind: 'query';
    };
    GET_ORDER_SUMMARY_LINK: {
        request: never;
        response: string;
        kind: 'query';
    };
    LIST_ORDERS: {
        request: FilterOrdersRequest;
        response: SimpleOrderResponse[];
        kind: 'query';
    };
    GET_LAST_STATUS_CHANGE: {
        request: never;
        response: OrderStatusChangeResponse | undefined;
        kind: 'query';
    };
    EDIT_ORDER: {
        request: EditOrderRequest;
        response: never;
        kind: 'command';
    };
    CHANGE_STATUS: {
        request: OrderStatusChangeRequest;
        response: never;
        kind: 'command';
    };
    APPROVE: {
        request: string;
        response: never;
        kind: 'command';
    };
    REJECT: {
        request: string;
        response: never;
        kind: 'command';
    };
    UNDO_STATUS_CHANGE: {
        request: never;
        response: never;
        kind: 'command';
    };
    CREATE_LINKED_RUN: {
        request: never;
        response: never;
        kind: 'command';
    };
};

export const ORDERS_ENDPOINTS = {
    GET_ORDER_ALLOWED_STATES: {
        path: `${API_BASE}/order/allowed-state-changes`,
        method: 'GET',
        kind: 'query',
    },
    GET_ORDER_STATUS_CHANGE_KINDS: {
        path: `${API_BASE}/order/status-change-kinds`,
        method: 'GET',
        kind: 'query',
    },
    GET_ORDER_TERMINAL_STATUSES: {
        path: `${API_BASE}/order/terminal-statuses`,
        method: 'GET',
        kind: 'query',
    },
    GET_ORDER: {
        path: `${API_BASE}/order/{orderId}`,
        method: 'GET',
        kind: 'query',
    },
    GET_ORDER_SUMMARY_LINK: {
        path: `${API_BASE}/order/{orderId}/summary-link`,
        method: 'GET',
        kind: 'query',
    },
    LIST_ORDERS: {
        path: `${API_BASE}/orders`,
        method: 'POST',
        kind: 'query',
    },
    GET_LAST_STATUS_CHANGE: {
        path: `${API_BASE}/order/{orderId}/last-status-change`,
        method: 'GET',
        kind: 'query',
    },
    EDIT_ORDER: {
        path: `${API_BASE}/order/{orderId}`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Order updated',
    },
    CHANGE_STATUS: {
        path: `${API_BASE}/order/{orderId}/change-status`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Order Updated',
    },
    APPROVE: {
        path: `${API_BASE}/order/{orderId}/approve`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Order Approved',
    },
    REJECT: {
        path: `${API_BASE}/order/{orderId}/reject`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Order Rejected',
    },
    UNDO_STATUS_CHANGE: {
        path: `${API_BASE}/order/{orderId}/undo-change-status`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Order Updated',
    },
    CREATE_LINKED_RUN: {
        path: `${API_BASE}/order/{orderId}/create-linked-run`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Run Created & Scheduled',
        invalidates: ['orders/GET_ORDER', 'orders/LIST_ORDERS'],
    },
} as const satisfies EndpointDefinitions<OrdersEndpointDefinitions>;

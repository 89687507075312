import type { EndpointDefinitions } from '@/features/api';
import { API_BASE } from '@/shared/constants';
import type {
    AcceptInviteRequest,
    ChangePasswordRequest,
    PasswordResetRequest,
    RefreshTokenRequest,
    SendPasswordResetRequest,
    UserLoginRequest,
    UserRegistrationRequest,
    VerifyEmailRequest,
} from './authApiRequestModels';
import type { AuthSuccessResponse, RoleResponse } from './authApiResponseModels';

const BASE_PATH = `${API_BASE}/identity` as const;

export type AuthEndpointDefinitions = {
    LOGIN: {
        request: UserLoginRequest;
        response: AuthSuccessResponse;
        kind: 'command';
    };
    REGISTER: {
        request: UserRegistrationRequest;
        response: never;
        kind: 'command';
    };
    VERIFY_EMAIL: {
        request: VerifyEmailRequest;
        response: never;
        kind: 'command';
    };
    ACCEPT_INVITE: {
        request: AcceptInviteRequest;
        response: never;
        kind: 'command';
    };
    RESET_PASSWORD: {
        request: PasswordResetRequest;
        response: never;
        kind: 'command';
    };
    CHANGE_PASSWORD: {
        request: ChangePasswordRequest;
        response: never;
        kind: 'command';
    };
    SEND_EMAIL_VERIFICATION: {
        request: UserLoginRequest;
        response: never;
        kind: 'command';
    };
    RESEND_INVITE: {
        request: never;
        response: never;
        kind: 'command';
    };
    REQUEST_PASSWORD_RESET: {
        request: SendPasswordResetRequest;
        response: never;
        kind: 'command';
    };
    VALIDATE_SESSION: {
        request: never;
        response: never;
        kind: 'query';
    };
    REFRESH_TOKEN: {
        request: RefreshTokenRequest;
        response: AuthSuccessResponse;
        kind: 'command';
    };
    GET_ROLES: {
        request: never;
        response: RoleResponse[];
        kind: 'query';
    };
    GET_PERMS: {
        request: never;
        response: string[];
        kind: 'query';
    };
};

export const AUTH_ENDPOINTS = {
    LOGIN: {
        path: `${BASE_PATH}/login`,
        method: 'POST',
        kind: 'command',
    },
    REGISTER: {
        path: `${BASE_PATH}/register`,
        method: 'POST',
        kind: 'command',
    },
    VERIFY_EMAIL: {
        path: `${BASE_PATH}/verify-email`,
        method: 'POST',
        kind: 'command',
    },
    ACCEPT_INVITE: {
        path: `${BASE_PATH}/accept-invite`,
        method: 'POST',
        kind: 'command',
    },
    RESET_PASSWORD: {
        path: `${BASE_PATH}/reset-password`,
        method: 'POST',
        kind: 'command',
    },
    CHANGE_PASSWORD: {
        path: `${BASE_PATH}/change-password`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Password changed',
    },
    SEND_EMAIL_VERIFICATION: {
        path: `${BASE_PATH}/send-email-verification`,
        method: 'POST',
        kind: 'command',
    },
    RESEND_INVITE: {
        path: `${BASE_PATH}/resend-invite/{userId}`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Invite resent successfully',
    },
    REQUEST_PASSWORD_RESET: {
        path: `${BASE_PATH}/request-password-reset`,
        method: 'POST',
        kind: 'command',
    },
    VALIDATE_SESSION: {
        path: `${BASE_PATH}/validate-session`,
        method: 'GET',
        kind: 'query',
    },
    REFRESH_TOKEN: {
        path: `${BASE_PATH}/refresh-token`,
        method: 'POST',
        kind: 'command',
    },
    GET_ROLES: {
        path: `${API_BASE}/roles`,
        method: 'GET',
        kind: 'query',
    },
    GET_PERMS: {
        path: `${API_BASE}/permissions`,
        method: 'GET',
        kind: 'query',
    },
} as const satisfies EndpointDefinitions<AuthEndpointDefinitions>;

import type { EndpointDefinitions } from '@/features/api';
import { API_BASE } from '@/shared/constants';
import type { PricingConfigResponse } from './pricingApiResponseModels';

export type PricingEndpointDefinitions = {
    GET_CONFIG: {
        request: never;
        response: PricingConfigResponse;
        kind: 'query';
    };
    GET_DISCOUNT_PROGRESSION: {
        request: never;
        response: number[];
        kind: 'query';
    };
};

export const PRICING_ENDPOINTS = {
    GET_CONFIG: {
        path: `${API_BASE}/pricing/config`,
        method: 'GET',
        kind: 'query',
    },
    GET_DISCOUNT_PROGRESSION: {
        path: `${API_BASE}/pricing/discounts/{months}`,
        method: 'GET',
        kind: 'query',
    },
} as const satisfies EndpointDefinitions<PricingEndpointDefinitions>;

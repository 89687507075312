import type { EndpointDefinitions } from '@/features/api';
import { API_BASE } from '@/shared/constants';
import { FileStatus } from './fileApiTypes';
import type { GetPresignedUrlRequest } from './filesApiRequestModels';
import type {
    BasicFileResponse,
    FileDetailsResponse,
    FileStatusResponse,
    GetPresignedUrlResponse,
} from './filesApiResponseModels';

const FILE_API_BASE = `${API_BASE}/file-storage`;

export type FilesEndpointDefinitions = {
    GET_FILE_DETAILS: {
        request: never;
        response: FileDetailsResponse;
        kind: 'query';
    };
    GET_STATUS: {
        request: never;
        response: FileStatus;
        kind: 'query';
    };
    GET_STATUS_DETAILS: {
        request: never;
        response: FileStatusResponse;
        kind: 'query';
    };
    LIST_FILES: {
        request: never;
        response: BasicFileResponse[];
        kind: 'query';
    };
    GET_PRESIGNED_URL: {
        request: GetPresignedUrlRequest;
        response: GetPresignedUrlResponse;
        kind: 'command';
    };
    QUEUE_FOR_PROCESSING: {
        request: never;
        response: never;
        kind: 'command';
    };
    NOTIFY_UPLOAD_STARTED: {
        request: never;
        response: never;
        kind: 'command';
    };
    NOTIFY_UPLOAD_COMPLETED: {
        request: never;
        response: never;
        kind: 'command';
    };
    NOTIFY_UPLOAD_FAILED: {
        request: string;
        response: never;
        kind: 'command';
    };
};

export const FILES_ENDPOINTS = {
    GET_FILE_DETAILS: {
        path: `${FILE_API_BASE}/{fileId}`,
        method: 'GET',
        kind: 'query',
    },
    GET_STATUS: {
        path: `${FILE_API_BASE}/{fileId}/status`,
        method: 'GET',
        kind: 'query',
    },
    GET_STATUS_DETAILS: {
        path: `${FILE_API_BASE}/{fileId}/status/details`,
        method: 'GET',
        kind: 'query',
    },
    LIST_FILES: {
        path: `${FILE_API_BASE}/files`,
        method: 'GET',
        kind: 'query',
    },
    GET_PRESIGNED_URL: {
        path: `${FILE_API_BASE}/get-presigned-url`,
        method: 'POST',
        kind: 'command',
    },
    NOTIFY_UPLOAD_STARTED: {
        path: `${FILE_API_BASE}/{fileId}/notify-upload-started`,
        method: 'POST',
        kind: 'command',
    },
    QUEUE_FOR_PROCESSING: {
        path: `${FILE_API_BASE}/{fileId}/queue-processing`,
        method: 'POST',
        kind: 'command',
    },
    NOTIFY_UPLOAD_COMPLETED: {
        path: `${FILE_API_BASE}/{fileId}/notify-upload-completed`,
        method: 'POST',
        kind: 'command',
    },
    NOTIFY_UPLOAD_FAILED: {
        path: `${FILE_API_BASE}/{fileId}/notify-upload-failed`,
        method: 'POST',
        kind: 'command',
    },
} as const satisfies EndpointDefinitions<FilesEndpointDefinitions>;

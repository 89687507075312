import type { TokenProvider } from '@/features/auth/tokenProvider';
import type { ErrorsResponse } from '../models';

let tokenProvider: TokenProvider | null = null;
export function setFetchTokenProvider(provider: TokenProvider) {
    tokenProvider = provider;
}

export async function authedFetch<TResult>(input: RequestInfo, init?: RequestInit) {
    if (!tokenProvider) throw new Error('Token provider not set');

    const token = tokenProvider.getToken();

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.token}`,
    };

    const localInit = init || {};
    localInit.headers = localInit.headers || {};
    localInit.headers = { ...localInit.headers, ...headers };

    const response = await fetch(input, init);

    if (!response.ok) {
        const responseBody = await getResponseValue<ErrorsResponse>(response);
        const error = new Error();

        if (responseBody) {
            Object.assign(error, responseBody);
        } else {
            error.message = response.statusText;
        }

        Object.assign(error, {
            status: response.status,
            statusText: response.statusText,
        });

        throw error;
    }

    return await getResponseValue<TResult>(response);
}

/** Useful for things that need to run before auth, such as logging in */
export async function unAuthedFetch<TResult>(input: RequestInfo, init?: RequestInit) {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    const localInit = init || {};
    localInit.headers = localInit.headers || {};
    localInit.headers = { ...localInit.headers, ...headers };

    const response = await fetch(input, init);

    if (!response.ok) {
        const responseBody = await getResponseValue<ErrorsResponse>(response);
        const error = new Error();

        if (responseBody) {
            Object.assign(error, responseBody);
        }

        throw error;
    }

    return await getResponseValue<TResult>(response);
}

async function getResponseValue<T>(response: Response) {
    const responseText = await response.text();

    if (!responseText) return '' as unknown as T;
    return JSON.parse(responseText) as T;
}

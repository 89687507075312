import type { EndpointDefinitions } from '@/features/api';
import { API_BASE } from '@/shared/constants';
import type { ScreenResponse } from './screenApiResponseModels';

export type ScreenEndpointDefinitions = {
    GET_SCREENS: {
        request: never;
        response: ScreenResponse[];
        kind: 'query';
    };
    GET_SCREENS_BOUNDS: {
        request: never;
        response: [[number, number], [number, number]];
        kind: 'query';
    };
};

export const SCREEN_ENDPOINTS = {
    GET_SCREENS: {
        path: `${API_BASE}/screens`,
        method: 'GET',
        kind: 'query',
    },
    GET_SCREENS_BOUNDS: {
        path: `${API_BASE}/screens/bbox`,
        method: 'GET',
        kind: 'query',
    },
} as const satisfies EndpointDefinitions<ScreenEndpointDefinitions>;

import { callApiWithoutAuth } from '@/features/api/utilities';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useForgotPasswordStore = defineStore('forgotPasswordStore', () => {
    const email = ref('');

    function setEmail(emailArg: string) {
        email.value = emailArg;
    }

    async function sendResetLink(email: string) {
        if (!email) throw new Error('Email is required to send reset link');

        const result = await callApiWithoutAuth('auth/REQUEST_PASSWORD_RESET', {
            email,
        });

        return result;
    }

    return {
        email,
        setEmail,
        sendResetLink,
    };
});

import type { EndpointDefinitions } from '@/features/api';
import { API_BASE } from '@/shared/constants';
import type { DevtoolsSeededUserResponse } from './devtoolsApiResponseModels';

const BASE_PATH = `${API_BASE}/dev`;

export type DevtoolsEndpointDefinitions = {
    GET_SEEDED_USERS: {
        request: never;
        response: DevtoolsSeededUserResponse[];
        kind: 'query';
    };
};

export const DEVTOOLS_ENDPOINTS = {
    GET_SEEDED_USERS: {
        path: `${BASE_PATH}/get-users`,
        method: 'GET',
        kind: 'query',
    },
} as const satisfies EndpointDefinitions<DevtoolsEndpointDefinitions>;

import type { EndpointDefinitions } from '@/features/api';
import type { EntitySearchRequest } from '@/features/querying';
import { API_BASE } from '@/shared';
import type { InvoiceListResponse, InvoiceResponse, InvoiceStripeDetailsResponse } from './invoiceApiResponseModels';

export type InvoicesEndpointDefinitions = {
    SEARCH_INVOICES: {
        request: Partial<EntitySearchRequest>;
        response: InvoiceListResponse[];
        kind: 'query';
    };
    GET_INVOICE: {
        request: never;
        response: InvoiceResponse;
        kind: 'query';
    };
    GET_INVOICE_STRIPE_URLS: {
        request: never;
        response: InvoiceStripeDetailsResponse;
        kind: 'query';
    };
    ADMIN_MARK_PAID: {
        request: never;
        response: never;
        kind: 'command';
    };
};

export const INVOICE_ENDPOINTS = {
    SEARCH_INVOICES: {
        path: `${API_BASE}/invoices/search`,
        method: 'POST',
        kind: 'query',
    },
    GET_INVOICE: {
        path: `${API_BASE}/invoices/{invoiceId}`,
        method: 'GET',
        kind: 'query',
    },
    GET_INVOICE_STRIPE_URLS: {
        path: `${API_BASE}/invoices/{invoiceId}/stripe-urls`,
        method: 'GET',
        kind: 'query',
    },
    ADMIN_MARK_PAID: {
        path: `${API_BASE}/invoices/{invoiceId}/mark-paid`,
        method: 'POST',
        kind: 'command',
        invalidates: [
            'dashboard/GET_PENDING_INVOICES',
            'invoice/GET_INVOICE',
            'orders/GET_ORDER',
            'orders/LIST_ORDERS',
        ],
    },
} as const satisfies EndpointDefinitions<InvoicesEndpointDefinitions>;

import { useApi } from '@/features/api/hooks/useApi';
import { computed } from 'vue';
import type { RoleResponse } from '../api/authApiResponseModels';
import { useCapabilities } from './useCapabilities';

export function useAdministerableAppRoles() {
    const { hasAtLeastRole } = useCapabilities();
    const { isLoading, data } = useApi('auth/GET_ROLES', (endpoint, fetcher) => {
        return fetcher(endpoint);
    }, { staleTime: 60000, cacheTime: 60000 });

    /** The roles the user can administer. Roles >= theirs. */
    const administerableRoles = computed(() => {
        return data.value ? data.value.filter((x) => hasAtLeastRole(x.name)) : [] as RoleResponse[];
    });

    /** Preformatted roles for select components */
    const selectRoles = computed(() => {
        return administerableRoles.value.map((x) => ({ title: x.friendlyName, value: x.name }));
    });

    return {
        isLoading,
        roles: administerableRoles,
        selectRoles,
    };
}

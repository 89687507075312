/**
 * Flag used to exclude a property from a search
 */
export interface ExclusionFlag {
    value?: string; // The key to exclude
    maxDepth: number; // WIll only exclude this key if the depth is lower than this. A value of 0 always excludes
}

export const filterBySearch = <TInput>(
    array: TInput[],
    search: string,
    excludedKeys: Partial<{ [key in keyof TInput]: ExclusionFlag; }> = {},
) => {
    const output: TInput[] = [];
    const searchLower = search.toLowerCase();

    array.forEach((item) => {
        // Only search if search input exists
        if (search !== null && search !== undefined && search !== '') {
            if (
                filterObject(
                    item as Record<string, unknown>,
                    searchLower,
                    excludedKeys as Record<string, ExclusionFlag>,
                )
            ) {
                output.push(item);
            }
        }
    });

    return output;
};

export const filterObject = (
    item: Record<string, unknown> | undefined,
    search: string,
    excludedKeys: Record<string, ExclusionFlag> = {},
    level = 0,
): boolean => {
    if (!item) return false;
    const keys = Object.keys(item);

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (item[key] === null || item[key] === undefined) {
            continue;
        }

        // key is excluded, and current depth is deeper that the keys max depth
        if (key in excludedKeys && level <= excludedKeys[key].maxDepth) {
            continue;
        }

        if (typeof item[key] === 'string') {
            if ((item[key] as string).toLowerCase().includes(search)) {
                return true;
            }
        } else if (Array.isArray(item[key])) {
            if (filterArray(item[key] as never[], search, excludedKeys, level + 1)) {
                return true;
            }
        } else if (typeof item[key] === 'object') {
            if (filterObject(item[key] as Record<string, unknown>, search, excludedKeys, level + 1)) {
                return true;
            }
        }
    }

    return false;
};

export const filterArray = (
    array: unknown[],
    search: string,
    excludedKeys: Record<string, ExclusionFlag> = {},
    level = 0,
): boolean => {
    for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if (typeof element === 'string') {
            if ((element).toLowerCase().includes(search)) {
                return true;
            }
        } else if (Array.isArray(element)) {
            if (filterArray(element as never[], search, excludedKeys, level + 1)) {
                return true;
            }
        } else if (typeof element === 'object') {
            if (filterObject(element as Record<string, unknown>, search, excludedKeys, level + 1)) {
                return true;
            }
        }
    }

    return false;
};

import type { ErrorsResponse } from '@/features/api/models';

type SuccessResult = {
    success: true;
    message?: string;
};

type FailResult = {
    success: false;
    message: string;
};

// Use discriminated unions for more ergonomic types
export type SimpleResult = SuccessResult | FailResult;

type SuccessApiResult<TData = never> = {
    success: true;
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    data: TData extends never | undefined ? unknown : TData;
};

type FailApiResult = {
    success: false;
    error: ErrorsResponse;
};

// Use discriminated unions for more ergonomic types
export type SimpleApiResult<TData = never> = SuccessApiResult<TData> | FailApiResult;

export function isFailApiResult(result: SimpleResult | SimpleApiResult): result is SimpleApiResult {
    return !!(result as FailApiResult)['error'];
}

export function isSimpleResult(result: SimpleResult | SimpleApiResult): result is SimpleResult {
    return !(result as FailApiResult)['error'] && !!(result as SimpleResult)['message'];
}

import { callApiWithoutAuth } from '@/features/api/utilities';
import { defineStore } from 'pinia';
import { ref } from 'vue';

interface RegisterArgs {
    name: string;
    email: string;
    company: string;
    password: string;
    forceUseCompromisedPassword: boolean;
}

export const useRegisterStore = defineStore('registerStore', () => {
    const cachedEmail = ref('');
    const cachedPassword = ref('');

    async function register({ name, email, company, password, forceUseCompromisedPassword }: RegisterArgs) {
        const result = await callApiWithoutAuth('auth/REGISTER', {
            name,
            email,
            company,
            password,
            forceUseCompromisedPassword,
        });

        if (result.success) {
            cachedEmail.value = email;
            cachedPassword.value = password;
        }

        return result;
    }

    async function resendVerificationEmail() {
        // if (!cachedEmail.value || !cachedPassword.value) throw new Error('No cached email or password');

        const result = await callApiWithoutAuth('auth/SEND_EMAIL_VERIFICATION', {
            email: cachedEmail.value,
            password: cachedPassword.value,
        });

        return result;
    }

    return {
        email: cachedEmail,
        password: cachedPassword,
        register,
        resendVerificationEmail,
    };
});

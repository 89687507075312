import type { JwtTokenStructure } from '../models';

export class Token {
    private decoded: JwtTokenStructure;

    constructor(decoded: JwtTokenStructure) {
        this.decoded = decoded;
    }

    public getPayload(): JwtTokenStructure {
        return this.decoded;
    }

    public get expiry() {
        return this.decoded.exp;
    }

    public get perms() {
        return this.decoded.perm;
    }

    public get userId() {
        return this.decoded.userId;
    }

    public get email() {
        return this.decoded.email;
    }

    public get userRole() {
        return this.decoded.role;
    }

    public get companyId() {
        return this.decoded.companyId as unknown as number;
    }

    public get changePwd() {
        return !!this.decoded.changePwd;
    }

    public get companyType() {
        return this.decoded.companyType;
    }

    public get dataKey() {
        return this.decoded.dataKey;
    }
}

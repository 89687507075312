/* eslint-disable import/namespace */
import { callApi } from '@/features/api/utilities';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useQueryClient } from 'vue-query';
import { useRouter } from 'vue-router';
import { SUPER_ADMIN_ROLE } from '../config';
import type { JwtToken } from '../jwtManager';
import { JwtManager } from '../jwtManager';
import type { JwtTokenStructure } from '../models';
import type { UserAbility } from '../perms/perms';
import { createEmptyUserAbility, createUserAbility } from '../perms/perms';

import * as Sentry from '@sentry/vue';
import { useAppRoles } from '../hooks/useAppRoles';

const clearableRefreshErrors = [
    1402, // Token does not exist
    1403, // Token has expired
    1404, // Token invalidated
    1405, // Token already used
    1406, // Token does not match JWT
] as const;

const jwtManager = new JwtManager();

interface SentryUser {
    id: string;
    email: string;
    companyId: number;
    role: string;
}
function setSentryUser(user: SentryUser | null) {
    Sentry.setUser(user);
}

export const useAuthStore = defineStore('authStore', () => {
    const initPromise = ref<Promise<void> | null>(null);
    const loading = ref(true);
    const initToken = ref<JwtToken | null>(null); // Used as a source token during init, if it exists
    const authenticated = ref(false);
    const changePasswordRequired = ref(false);

    const decoded = ref<JwtTokenStructure | null>(null);
    const router = useRouter();
    const queryClient = useQueryClient();

    const { roles } = useAppRoles(computed(() => !!initToken.value || authenticated.value));
    const userAbility = ref<UserAbility>(createEmptyUserAbility());

    const isSuperAdmin = computed(() => {
        if (!decoded.value) return null;

        return decoded.value.role === SUPER_ADMIN_ROLE;
    });

    const userId = computed(() => {
        if (!decoded.value) return null;

        return decoded.value.sub;
    });

    const companyId = computed(() => {
        if (!decoded.value) return null;

        return parseInt(decoded.value.companyId);
    });

    jwtManager.on('token-refreshed', async () => {
        authenticated.value = true;

        const decodedToken = jwtManager.decodedToken;
        if (!decodedToken) throw new Error('No decoded token found');

        changePasswordRequired.value = !!decodedToken.changePwd;

        let localRoles = roles.value; // Super gross, but I need roles to be loaded at this point. What we should do is setup an init signal we await
        if (!localRoles?.length) {
            const apiRoles = await callApi('auth/GET_ROLES');
            if (apiRoles.success) {
                localRoles = apiRoles.data;
            }
        }
        if (!localRoles?.length) throw new Error('No roles found');

        userAbility.value = createUserAbility({
            userId: decodedToken.userId,
            userRole: decodedToken.userRole,
            companyId: decodedToken.companyId,
            companyType: decodedToken.companyType,
            dataKey: decodedToken.dataKey,
            capabilities: decodedToken.perms,
            roles: localRoles,
        });

        decoded.value = decodedToken.getPayload();

        setSentryUser({
            id: decodedToken.userId,
            email: decodedToken.email,
            companyId: decodedToken.companyId,
            role: decodedToken.userRole,
        });
    });

    jwtManager.on('token-set', async () => {
        authenticated.value = true;

        const decodedToken = jwtManager.decodedToken;
        if (!decodedToken) throw new Error('No decoded token found');

        changePasswordRequired.value = !!decodedToken.changePwd;

        let localRoles = roles.value;
        if (!localRoles?.length) { // Super gross, but I need roles to be loaded at this point. What we should do is setup an init signal we await
            const apiRoles = await callApi('auth/GET_ROLES');
            if (apiRoles.success) {
                localRoles = apiRoles.data;
            }
        }
        if (!localRoles?.length) throw new Error('No roles found');

        userAbility.value = createUserAbility({
            userId: decodedToken.userId,
            userRole: decodedToken.userRole,
            companyId: decodedToken.companyId,
            companyType: decodedToken.companyType,
            dataKey: decodedToken.dataKey,
            capabilities: decodedToken.perms,
            roles: localRoles,
        });

        decoded.value = decodedToken.getPayload();

        setSentryUser({
            id: decodedToken.userId,
            email: decodedToken.email,
            companyId: decodedToken.companyId,
            role: decodedToken.userRole,
        });
    });

    jwtManager.on('token-expired', () => {
        authenticated.value = false;
        changePasswordRequired.value = false;
        userAbility.value = createEmptyUserAbility();
        decoded.value = null;
        setSentryUser(null);
    });

    jwtManager.on('token-removed', () => {
        authenticated.value = false;
        changePasswordRequired.value = false;
        userAbility.value = createEmptyUserAbility();
        decoded.value = null;
        setSentryUser(null);
    });

    /** Sets the login token */
    function setLogin(jwt: JwtToken) {
        jwtManager.setToken(jwt);
        watchForExpiringToken(jwt);
    }

    async function refreshAuth({ token, refreshToken }: JwtToken) {
        const result = await callApi('auth/REFRESH_TOKEN', {
            token,
            refreshToken,
        });

        if (result.success) {
            jwtManager.setToken(result.data);
            watchForExpiringToken(result.data);
        } else {
            if (clearableRefreshErrors.some((code) => result.error.errors[0].code === code)) {
                jwtManager.forget();
            } else {
                watchForExpiringToken({ token, refreshToken }); // Will keep retrying each trigger till the token expires
            }
        }
    }

    function watchForExpiringToken(jwt: JwtToken) {
        const unSubExpired = jwtManager.once('token-expired', (expiringToken) => {
            void refreshAuth(expiringToken);
        }, (tokenArg) => tokenArg.token === jwt.token);

        jwtManager.once('token-expiring', (expiringToken) => {
            void refreshAuth(expiringToken);
            unSubExpired();
        }, (tokenArg) => tokenArg.token === jwt.token);
    }

    // Implementation of TokenSource
    function getToken() {
        if (initToken.value) return initToken.value;

        const token = jwtManager.getToken();
        if (!token) throw new Error('No token found');

        return token;
    }

    async function init() {
        const { promise, resolve } = Promise.withResolvers<void>();
        initPromise.value = promise;

        const jwt = jwtManager.getToken();
        if (jwt && jwtManager.hasTokenExpired() || jwtManager.isTokenExpiringSoon()) {
            await refreshAuth(getToken());
        } else if (jwt) {
            initToken.value = jwt;
            setLogin(jwt);
        }

        await new Promise((resolve) => setTimeout(resolve, 500));

        initToken.value = null;
        loading.value = false;
        resolve();
    }

    function logout() {
        clearAuth();
        void router.push({ name: 'Login' });
        void queryClient.removeQueries();
    }

    /** Effectively logs the user out without redirecting to login */
    function clearAuth() {
        Sentry.setUser(null);
        jwtManager.forget();
    }

    return {
        loading,
        decoded,
        authenticated,
        userId,
        companyId,
        userAbility,
        isSuperAdmin,
        changePasswordRequired,
        initPromise,
        init,
        getToken,
        setLogin,
        logout,
        clearAuth,
    };
});

import type { Actions, Scopes, Subjects } from './types';

/** Splits the capability string into it's constituent parts */
export function getSplitCapability(capability: string) {
    const split = capability.split(':');

    if (split.length === 4) {
        return [`${split[0]}:${split[1]}`, split[2], split[3]] as [Subjects, Actions, Scopes];
    }

    if (split.length === 3) {
        return split as [Subjects, Actions, Scopes];
    }

    throw new Error(`Invalid capability: ${capability}`);
}

/** Splits the capability string into it's constituent parts, discarding the scope */
export function getScopelessSplitCapability(capability: string) {
    const split = capability.split(':');

    if (split.length === 3) {
        return [`${split[0]}:${split[1]}`, split[2]] as [Subjects, Actions];
    }

    if (split.length === 2) {
        return split as [Subjects, Actions];
    }

    throw new Error(`Invalid capability: ${capability}`);
}

/** Splits a dataKey (1|6|15) into an array */
export function splitDataKey(dataKey: string) {
    return dataKey.split('|').map((x) => Number(x.trim()));
}

/** Is the user a direct parent of the company the entity belongs to */
export function isDirectParent(entityDataKey: string | null, userCompanyId: number) {
    if (!entityDataKey) return false;

    // For example (1|6|15) => [1, 6, 15]
    // This is [root company, parent company, company]
    const keys = splitDataKey(entityDataKey);

    // If the split length is 1, then there can be no parent
    if (keys.length === 1) return false;

    const index = keys.indexOf(userCompanyId);

    // If the index is less than the length - 1, then the user is a parent
    // If the index is the last index, then the user is the same company
    return index < keys.length - 1;
}

/** Is the user part of the same company as the company the entity belongs to */
export function isSameCompany(entityDataKey: string | null, userCompanyId: number) {
    if (!entityDataKey) return false;

    return entityDataKey.endsWith(`|${userCompanyId}`);
}

import type { CompanyType } from '@/features/companies/api/companyApiResponseModels';
import type { LiteralUnion } from 'type-fest';
import type { AppRoles, RoleResponse } from '../api/authApiResponseModels';
import type { CompanyResource, CreatedByResource, ResourceArg, ResourceConstraintOptions } from './abilityTypes';
import { isCompanyConstraint, isCreatedByConstraint } from './abilityTypes';
import type { Capability } from './constants';
import { SCOPES } from './constants';
import type { JoinedCapabilities } from './types';
import { getScopelessSplitCapability, getSplitCapability, isDirectParent, isSameCompany } from './utils';

const SUPER_ADMIN_ROLE = 'SuperAdmin';

interface CreateCapabilityArgs {
    userId: string;
    userRole: string;
    companyId: number;
    companyType: CompanyType;
    dataKey: string;
    capabilities: string[];
    roles: RoleResponse[];
}

export function createEmptyUserAbility() {
    return createUserAbility({
        userId: '',
        userRole: '',
        companyId: 0,
        companyType: 'Company',
        dataKey: '',
        capabilities: [],
        roles: [],
    });
}

function createCompositeCache() {
    const mapCache = new Map<string, boolean>();
    const concat = (dataKey: string | null, localCompanyId: number) => `${dataKey}:${localCompanyId}`;

    const has = (dataKey: string | null, localCompanyId: number) => {
        const key = concat(dataKey, localCompanyId);
        return !!mapCache.get(key);
    };

    const get = (dataKey: string | null, localCompanyId: number) => {
        const key = concat(dataKey, localCompanyId);
        return mapCache.get(key);
    };

    const set = (dataKey: string | null, localCompanyId: number, can: boolean) => {
        const key = concat(dataKey, localCompanyId);
        return mapCache.set(key, can);
    };

    return {
        has,
        get,
        set,
    };
}

export function createUserAbility(
    { userId, userRole, companyId, companyType, capabilities, roles }: CreateCapabilityArgs,
) {
    capabilities = capabilities.filter((x) => x.includes(':'));
    const cache = new Map<string, boolean>();
    const rolesCache = new Map<string, RoleResponse>(roles.map(role => [role.name, role]));
    const exclusiveCache = new Map<string, boolean>();
    const companyConstraintCache = createCompositeCache();

    function capabilityHasScope<TCapability extends JoinedCapabilities>(capability: TCapability) {
        const lastSeparator = capability.lastIndexOf(':');
        const slice = capability.slice(Math.min(lastSeparator + 1, capability.length));

        return SCOPES.has(slice);
    }

    /** The user has this capability and scope exclusively and has no other capability for the same subject & action */
    function canExclusive<TCapability extends Capability>(capability: TCapability): boolean {
        if (exclusiveCache.has(capability)) {
            return exclusiveCache.get(capability)!;
        }

        if (!can(capability)) {
            exclusiveCache.set(capability, false);
            return false;
        }

        const [subject, action, _scope] = getSplitCapability(capability);

        const found = capabilities.find((x) => {
            if (x === capability) return false; // Skip the same capability

            const [localSubject, localAction, _localScope] = getSplitCapability(x);

            if (localSubject === subject && localAction === action) {
                return true;
            }

            return false;
        });

        if (found) {
            exclusiveCache.set(capability, false);
            return false;
        }

        exclusiveCache.set(capability, true);
        return true;
    }

    function canInternal<TCapability extends JoinedCapabilities>(capability: TCapability | undefined): boolean {
        if (!capability) return true; // No capability required, we can

        if (cache.has(capability)) {
            return cache.get(capability)!;
        }

        const hasScope = capabilityHasScope(capability);

        if (hasScope) {
            if (capabilities.includes(capability)) {
                cache.set(capability, true);
                return true;
            }
            // TODO: Use `any/all` scopes for any scoped capability
            // const [subject, action, scope] = getSplitCapability(capability);
        }

        const [subject, action] = getScopelessSplitCapability(capability);

        const found = capabilities.find((x) => {
            const [localSubject, localAction, _localScope] = getSplitCapability(x);

            if (localSubject === subject && localAction === action) {
                return true;
            }

            return false;
        });

        if (found) {
            cache.set(capability, true);
            return true;
        }

        cache.set(capability, false);
        return false;
    }

    function can<TCapability extends JoinedCapabilities>(capability: TCapability | undefined): boolean;
    function can<TCapability extends JoinedCapabilities, TResourceOptions extends ResourceConstraintOptions>(
        capability: TCapability | undefined,
        resourceOpts: TResourceOptions,
        resourceEntity: ResourceArg<TResourceOptions>,
    ): boolean;
    function can<TCapability extends JoinedCapabilities, TResourceOptions extends ResourceConstraintOptions>(
        capability: TCapability | undefined,
        resourceOpts?: TResourceOptions,
        resourceEntity?: ResourceArg<TResourceOptions>,
    ): boolean {
        if (userRole === SUPER_ADMIN_ROLE) return true;

        if (!resourceOpts) {
            return canInternal(capability);
        }

        if (!canInternal(capability)) return false;

        if (!resourceEntity) throw new Error('Resource entity is required when resource options are provided');

        if (isCompanyConstraint(resourceOpts)) {
            const resEntity = resourceEntity as CompanyResource;
            if (companyConstraintCache.has(resEntity.dataKey, companyId)) {
                return companyConstraintCache.get(resEntity.dataKey, companyId)!;
            }

            if (companyType === 'Organization' || companyType === 'RootCompany') {
                if (
                    !isDirectParent(resEntity.dataKey, companyId)
                    && !isSameCompany(resEntity.dataKey, companyId)
                ) {
                    companyConstraintCache.set(resEntity.dataKey, companyId, false);
                    return false;
                }
            } else {
                if (!isSameCompany(resEntity.dataKey, companyId)) {
                    companyConstraintCache.set(resEntity.dataKey, companyId, false);
                    return false;
                }
            }

            companyConstraintCache.set(resEntity.dataKey, companyId, true);
        }

        if (isCreatedByConstraint(resourceOpts)) {
            const resEntity = resourceEntity as CreatedByResource;

            if (resEntity.createdById !== userId) {
                return false;
            }
        }

        return true;
    }

    /** Enforces that the user has at least this role */
    function hasAtLeastRole(role: LiteralUnion<AppRoles, string>) {
        if (!rolesCache.has(role)) return false;
        if (!rolesCache.has(userRole)) return false;
        if (role == 'RootUser') return false;
        if (role == 'SuperAdmin') return false;

        return rolesCache.get(role)!.level >= rolesCache.get(userRole)!.level;
    }

    function createdByUser(resourceEntity: CreatedByResource) {
        if (userRole === SUPER_ADMIN_ROLE) return true;

        return can(undefined, { createdBy: true }, resourceEntity);
    }

    return {
        can,
        createdByUser,
        canExclusive,
        hasAtLeastRole,
    };
}
export type UserAbility = ReturnType<typeof createUserAbility>;

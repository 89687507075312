import type { Ref } from 'vue';
import { computed, ref, toValue, watch } from 'vue';
import { IsPwned } from '../hibp';

const pwned = new IsPwned({ resolveOnTimeout: true, timeout: 3500 });

export function useIsPasswordPwned(passwordRef: Ref<string>) {
    const isPwnedResult = ref<{ count: number; breached: boolean; } | null>(null);
    const acceptCompromisedPassword = ref(false);
    const watchablePassword = computed(() => passwordRef.value);

    async function checkIsPwned() {
        const result = await pwned.isPwned(toValue(watchablePassword));
        isPwnedResult.value = result;
        return result;
    }

    // If the password changes, reset the error
    watch(watchablePassword, (newPass, oldPass) => {
        if (newPass === oldPass) return;

        isPwnedResult.value = null;
        acceptCompromisedPassword.value = false;
    });

    return {
        checkIsPwned,
        isPwnedResult,
        acceptCompromisedPassword,
    };
}

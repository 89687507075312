import type { JwtToken } from '../types';

export const JWT_KEY = 'jwt';
export const REFRESH_TOKEN_KEY = 'refreshToken';

export interface Store {
    /**
     * Store the token
     *
     * @param token
     * @return void
     */
    store(token: JwtToken): void;

    /**
     * Retrieve the token from the store
     *
     * @return string|null
     */
    retrieve(): JwtToken | null;

    /**
     * Forget the token from the store
     *
     * @return void
     */
    forget(): void;
}

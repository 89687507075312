import type { EndpointDefinitions } from '@/features/api';
import { API_BASE } from '@/shared/constants';
import type {
    EditMyUserRequest,
    EditUserRequest,
    InviteUserRequest,
    UsersSearchRequest,
} from './usersApiRequestModels';
import type { UserCompanyInfoResponse, UserResponse } from './usersApiResponseModels';

export type UsersEndpointDefinitions = {
    GET_ME: {
        request: never;
        response: UserResponse;
        kind: 'query';
    };
    SEARCH_USERS: {
        request: Partial<UsersSearchRequest>;
        response: UserResponse[];
        kind: 'query';
    };
    GET_USERS: {
        request: never;
        response: UserResponse[];
        kind: 'query';
    };
    GET_USER: {
        request: never;
        response: UserResponse;
        kind: 'query';
    };
    GET_USER_COMPANY_INFO: {
        request: never;
        response: UserCompanyInfoResponse;
        kind: 'query';
    };
    GET_PENDING_USERS: {
        request: never;
        response: UserResponse[];
        kind: 'query';
    };
    GET_PENDING_USERS_COUNT: {
        request: never;
        response: number;
        kind: 'query';
    };
    EDIT_USER: {
        request: EditUserRequest;
        response: never;
        kind: 'command';
    };
    CHANGE_PENDING_USER_EMAIL: {
        request: string;
        response: never;
        kind: 'command';
    };
    EDIT_ME: {
        request: EditMyUserRequest;
        response: never;
        kind: 'command';
    };
    INVITE_USER: {
        request: InviteUserRequest;
        response: UserResponse;
        kind: 'command';
    };
    CANCEL_INVITE: {
        request: never;
        response: never;
        kind: 'command';
    };
    REJECT_USER: {
        request: never;
        response: UserResponse;
        kind: 'command';
    };
    DEACTIVATE_USER: {
        request: never;
        response: never;
        kind: 'command';
    };
    ACTIVATE_USER: {
        request: never;
        response: never;
        kind: 'command';
    };
    APPROVE_USER: {
        request: never;
        response: UserResponse;
        kind: 'command';
    };
};

export const USERS_ENDPOINTS = {
    GET_ME: {
        path: `${API_BASE}/user/me`,
        method: 'GET',
        kind: 'query',
    },
    SEARCH_USERS: {
        path: `${API_BASE}/users/search`,
        method: 'POST',
        kind: 'query',
    },
    GET_USERS: {
        path: `${API_BASE}/users`,
        method: 'GET',
        kind: 'query',
    },
    GET_USER: {
        path: `${API_BASE}/user/{userId}`,
        method: 'GET',
        kind: 'query',
    },
    GET_USER_COMPANY_INFO: {
        path: `${API_BASE}/user/{userId}/company-info`,
        method: 'GET',
        kind: 'query',
    },
    GET_PENDING_USERS: {
        path: `${API_BASE}/users/pending`,
        method: 'GET',
        kind: 'query',
    },
    GET_PENDING_USERS_COUNT: {
        path: `${API_BASE}/users/pending/count`,
        method: 'GET',
        kind: 'query',
    },
    EDIT_USER: {
        path: `${API_BASE}/user/{userId}`,
        method: 'POST',
        kind: 'command',
        successMessage: 'User Saved',
        invalidates: ['users/'],
    },
    CHANGE_PENDING_USER_EMAIL: {
        path: `${API_BASE}/user/{userId}/change-pending-email`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Email Changed & Invite Resent',
        invalidates: ['users/'],
    },
    EDIT_ME: {
        path: `${API_BASE}/user/me`,
        method: 'POST',
        kind: 'command',
        successMessage: 'User Saved',
        invalidates: ['users/'],
    },
    INVITE_USER: {
        path: `${API_BASE}/company/{companyId}/user/invite`,
        method: 'POST',
        kind: 'command',
        successMessage: 'User Invited',
        invalidates: ['users/'],
    },
    CANCEL_INVITE: {
        path: `${API_BASE}/user/{userId}/cancel-invite`,
        method: 'POST',
        kind: 'command',
        successMessage: 'User Invite Cancelled',
        invalidates: ['users/'],
    },
    REJECT_USER: {
        path: `${API_BASE}/user/{userId}/reject`,
        method: 'POST',
        kind: 'command',
        successMessage: 'User rejected',
        invalidates: ['users/'],
    },
    DEACTIVATE_USER: {
        path: `${API_BASE}/user/{userId}/deactivate`,
        method: 'POST',
        kind: 'command',
        successMessage: 'User deactivated',
        invalidates: ['users/'],
    },
    ACTIVATE_USER: {
        path: `${API_BASE}/user/{userId}/activate`,
        method: 'POST',
        kind: 'command',
        successMessage: 'User activated',
        invalidates: ['users/'],
    },
    APPROVE_USER: {
        path: `${API_BASE}/user/{userId}/approve`,
        method: 'POST',
        kind: 'command',
        successMessage: 'User approved',
        invalidates: ['users/'],
    },
} as const satisfies EndpointDefinitions<UsersEndpointDefinitions>;

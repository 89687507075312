import type { EndpointDefinitions } from '@/features/api';
import { API_BASE } from '@/shared/constants';
import type { PaymentMethod } from '@stripe/stripe-js';
import type { CamelCasedPropertiesDeep } from 'type-fest';
import type { SetAutoPayRequest, SetupStripeAccountRequest } from './billingApiRequestModels';
import type { DefaultPaymentMethodResponse, StripeSessionResponse } from './billingApiResponseModels';

export type BillingEndpointDefinitions = {
    SET_CONTACT_EMAIL: {
        request: SetupStripeAccountRequest;
        response: never;
        kind: 'command';
    };
    SET_AUTOPAY: {
        request: SetAutoPayRequest;
        response: never;
        kind: 'command';
    };
    SETUP_STRIPE_CUSTOMER: {
        request: SetupStripeAccountRequest;
        response: never;
        kind: 'command';
    };
    GET_PAYMENT_SETUP_SESSION: {
        request: never;
        response: StripeSessionResponse;
        kind: 'command';
    };
    GET_PAYMENT_METHODS: {
        request: never;
        response: CamelCasedPropertiesDeep<PaymentMethod[]>;
        kind: 'query';
    };
    GET_DEFAULT_PAYMENT_METHOD: {
        request: never;
        response: DefaultPaymentMethodResponse;
        kind: 'query';
    };
    SET_DEFAULT_PAYMENT_METHOD: {
        request: never;
        response: never;
        kind: 'command';
    };
    DETACH_PAYMENT_METHOD: {
        request: never;
        response: never;
        kind: 'command';
    };
};

export const BILLING_ENDPOINTS = {
    SET_CONTACT_EMAIL: {
        path: `${API_BASE}/billing/{companyId}/set-email`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Contact email set',
        invalidates: ['company/GET_COMPANY'],
    },
    SET_AUTOPAY: {
        path: `${API_BASE}/billing/{companyId}/set-autopay`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Auto-pay set',
        invalidates: ['company/GET_COMPANY'],
    },
    SETUP_STRIPE_CUSTOMER: {
        path: `${API_BASE}/billing/{companyId}/setup-stripe-customer`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Billing account ready',
        invalidates: ['company/GET_COMPANY'],
    },
    GET_PAYMENT_SETUP_SESSION: {
        path: `${API_BASE}/billing/{companyId}/payment-setup-session`,
        method: 'POST',
        kind: 'command',
    },
    GET_PAYMENT_METHODS: {
        path: `${API_BASE}/billing/{companyId}/payment-methods`,
        method: 'GET',
        kind: 'query',
    },
    GET_DEFAULT_PAYMENT_METHOD: {
        path: `${API_BASE}/billing/{companyId}/payment-methods/default`,
        method: 'GET',
        kind: 'query',
    },
    SET_DEFAULT_PAYMENT_METHOD: {
        path: `${API_BASE}/billing/{companyId}/payment-methods/{paymentMethodId}/default`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Default payment method set',
        invalidates: ['billing/GET_DEFAULT_PAYMENT_METHOD'],
    },
    DETACH_PAYMENT_METHOD: {
        path: `${API_BASE}/billing/{companyId}/payment-methods/{paymentMethodId}/detach`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Payment method removed',
        invalidates: ['billing/GET_PAYMENT_METHODS', 'billing/GET_DEFAULT_PAYMENT_METHOD'],
    },
} as const satisfies EndpointDefinitions<BillingEndpointDefinitions>;

export function arraysEqual<TElement>(a: TElement[], b: TElement[]) {
    if (a.length !== b.length) return false;

    // .concat() to not mutate arguments
    const aSort = a.concat().sort();
    const bSort = b.concat().sort();

    for (let i = 0; i < aSort.length; i++) {
        if (aSort[i] !== bSort[i]) {
            return false;
        }
    }

    return true;
}

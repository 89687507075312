import { AUTH_ENDPOINTS, type AuthEndpointDefinitions } from '@/features/auth/api/auth.endpoints';
import type { BudgetEndpointDefinitions } from '@/features/budget/api';
import { BUDGET_ENDPOINTS } from '@/features/budget/api';
import type { CampaignsEndpointDefinitions } from '@/features/campaigns/api';
import { CAMPAIGNS_ENDPOINTS } from '@/features/campaigns/api';
import type { CreativeEndpointDefinitions } from '@/features/creatives';
import { CREATIVE_ENDPOINTS } from '@/features/creatives/api/';
import { DEVTOOLS_ENDPOINTS, type DevtoolsEndpointDefinitions } from '@/features/devtools';
import { INDUSTRY_ENDPOINTS, type IndustryEndpointDefinitions } from '@/features/industries/api/industry.endpoints';
import type { OrdersEndpointDefinitions } from '@/features/orders/api';
import { ORDERS_ENDPOINTS } from '@/features/orders/api';
import type { PricingEndpointDefinitions } from '@/features/pricing/api';
import { PRICING_ENDPOINTS } from '@/features/pricing/api';
import type { ScreenEndpointDefinitions } from '@/features/screens';
import { SCREEN_ENDPOINTS } from '@/features/screens/api';
import { USERS_ENDPOINTS, type UsersEndpointDefinitions } from '@/features/users';
import type { KeyLiterals, UnionToIntersection } from '@/shared/types';
import type { ConditionalKeys, Simplify } from 'type-fest';
import type { BillingEndpointDefinitions } from '../billing/api/billing.endpoints';
import { BILLING_ENDPOINTS } from '../billing/api/billing.endpoints';
import type { CompanyEndpointDefinitions } from '../companies';
import { COMPANY_ENDPOINTS } from '../companies/api';
import type { DashboardEndpointDefinitions } from '../dashboard/api/dashboard.endpoints';
import { DASHBOARD_ENDPOINTS } from '../dashboard/api/dashboard.endpoints';
import type { FilesEndpointDefinitions } from '../files/api/files.endpoints';
import { FILES_ENDPOINTS } from '../files/api/files.endpoints';
import type { InvoicesEndpointDefinitions } from '../invoices/api/invoice.endpoints';
import { INVOICE_ENDPOINTS } from '../invoices/api/invoice.endpoints';
import type { OrderRunsEndpointDefinitions } from '../orderRuns/api/orderRuns.endpoints';
import { ORDER_RUNS_ENDPOINTS } from '../orderRuns/api/orderRuns.endpoints';
import type { PrimitiveEndpointDefinition } from './types';
import type { PathParamsObject } from './utilities/types';

export type EndpointDefinitions = {
    auth: AuthEndpointDefinitions;
    dev: DevtoolsEndpointDefinitions;
    company: CompanyEndpointDefinitions;
    industry: IndustryEndpointDefinitions;
    users: UsersEndpointDefinitions;
    screens: ScreenEndpointDefinitions;
    files: FilesEndpointDefinitions;
    campaigns: CampaignsEndpointDefinitions;
    creatives: CreativeEndpointDefinitions;
    pricing: PricingEndpointDefinitions;
    orders: OrdersEndpointDefinitions;
    orderRuns: OrderRunsEndpointDefinitions;
    budget: BudgetEndpointDefinitions;
    dashboard: DashboardEndpointDefinitions;
    invoice: InvoicesEndpointDefinitions;
    billing: BillingEndpointDefinitions;
};

export const ENDPOINTS = {
    auth: AUTH_ENDPOINTS,
    dev: DEVTOOLS_ENDPOINTS,
    company: COMPANY_ENDPOINTS,
    industry: INDUSTRY_ENDPOINTS,
    users: USERS_ENDPOINTS,
    screens: SCREEN_ENDPOINTS,
    files: FILES_ENDPOINTS,
    campaigns: CAMPAIGNS_ENDPOINTS,
    creatives: CREATIVE_ENDPOINTS,
    pricing: PRICING_ENDPOINTS,
    orders: ORDERS_ENDPOINTS,
    orderRuns: ORDER_RUNS_ENDPOINTS,
    budget: BUDGET_ENDPOINTS,
    dashboard: DASHBOARD_ENDPOINTS,
    invoice: INVOICE_ENDPOINTS,
    billing: BILLING_ENDPOINTS,
} as const satisfies Record<keyof EndpointDefinitions, Record<string, PrimitiveEndpointDefinition>>;
export type EndpointLiterals = typeof ENDPOINTS;

// Create prefixes union type like auth/ | users/
export type EndpointPrefix = `${keyof EndpointDefinitions}/`;

// https://stackoverflow.com/questions/71397436/template-literal-type-for-a-nested-type
export type Endpoint = {
    [Key in keyof EndpointDefinitions]: `${Key}/${KeyLiterals<EndpointDefinitions[Key]>}`;
}[keyof EndpointDefinitions];

/** All endpoints that are queries and are available for data fetching*/
export type QueryEndpoint = {
    // @ts-expect-error Variance for symbol has error, but otherwise works fine
    [Key in keyof EndpointDefinitions]: `${Key}/${ConditionalKeys<EndpointDefinitions[Key], { kind: 'query'; }>}`;
}[keyof EndpointDefinitions];

/** All endpoints that are commands/mutations */
export type CommandEndpoint = {
    // @ts-expect-error Variance for symbol has error, but otherwise works fine
    [Key in keyof EndpointDefinitions]: `${Key}/${ConditionalKeys<EndpointDefinitions[Key], { kind: 'command'; }>}`;
}[keyof EndpointDefinitions];

// Mapped and template literal type to create an intersection of all the endpoint request types
export type EndpointModel = UnionToIntersection<
    {
        [Key in keyof EndpointDefinitions]: {
            // @ts-expect-error Variance for symbol has error, but otherwise works fine
            [Key2 in keyof EndpointDefinitions[Key] as `${Key}/${Key2}`]: EndpointDefinitions[Key][Key2];
        };
    }[keyof EndpointDefinitions]
>;

export type EndpointRequest = {
    [Key in keyof EndpointModel]: EndpointModel[Key]['request'];
};

export type EndpointResponse = {
    [Key in keyof EndpointModel]: EndpointModel[Key]['response'];
};

export type EndpointPaths = UnionToIntersection<
    {
        [Key in keyof EndpointDefinitions]: {
            // @ts-expect-error Variance for symbol has error, but otherwise works fine
            [Key2 in keyof EndpointLiterals[Key] as `${Key}/${Key2}`]: EndpointLiterals[Key][Key2]['path'];
        };
    }[keyof EndpointDefinitions]
>;

export type EndpointPathParams = {
    [Key in keyof EndpointPaths]: Simplify<PathParamsObject<EndpointPaths[Key]>>;
};

export const ENDPOINTS_BY_PREFIX: Record<string, string[]> = {};

export function isQueryKeyEndpointPrefix(prefix: string | [string, ...unknown[]]): prefix is EndpointPrefix {
    if (Array.isArray(prefix)) {
        return false;
    }
    return isEndpointPrefix(prefix);
}

export function isEndpointPrefix(prefix: string): prefix is EndpointPrefix {
    return prefix.endsWith('/');
}

export function getEndpointsByPrefix(prefix: EndpointPrefix) {
    if (!ENDPOINTS_BY_PREFIX[prefix]) {
        const endpoints = Object.keys(ENDPOINTS[prefix.slice(0, -1) as keyof EndpointLiterals]);
        ENDPOINTS_BY_PREFIX[prefix] = endpoints.map(endpoint => `${prefix}${endpoint}` as Endpoint);
    }

    return ENDPOINTS_BY_PREFIX[prefix];
}

// UNUSED FOR NOW. NEEDS MORE WORK/THOUGHT
/** Map of param names to the endpoints that use them */
export const { PARAMS_ENDPOINTS_MAP, ENDPOINTS_PARAMS_MAP } = mapParamEndpoints();

function mapParamEndpoints() {
    // [param]: [endpoint1, endpoint2]
    const paramMap: Record<string, string[]> = {};
    // [endpoint]: [param1, param2]
    const endpointMap: Record<string, string[]> = {};

    for (const [namespace, endpoints] of Object.entries(ENDPOINTS)) {
        for (const [endpointName, endpoint] of Object.entries(endpoints)) {
            const endpointDef = endpoint as PrimitiveEndpointDefinition;

            // We only care about mapping queries for invalidation
            if (endpointDef.kind === 'command') continue;

            const endpointKey = `${namespace}/${endpointName}`;

            // Get each param in the path like {companyId} or {campaignId}
            const pathParams = endpointDef.path.match(/{(.*?)}/g);
            if (pathParams) {
                // TODO: This should join paths that have multiple params into a single "key"
                //    as {companyId}/campaign/{campaignId} shouldn't match anything that just uses a {companyId}
                for (const param of pathParams) {
                    const paramName = param.slice(1, -1);
                    if (!paramMap[paramName]) {
                        paramMap[paramName] = [];
                    }

                    if (!endpointMap[endpointKey]) {
                        endpointMap[endpointKey] = [];
                    }

                    paramMap[paramName].push(endpointKey);
                    endpointMap[endpointKey].push(paramName);
                }
            }
        }
    }

    return {
        PARAMS_ENDPOINTS_MAP: paramMap,
        ENDPOINTS_PARAMS_MAP: endpointMap,
    };
}

// function getDynamicParamInvalidations<TEndpoint extends Endpoint>(endpoint: TEndpoint, params: PathParamsObject<TEndpoint>) {
//     const invalidations = [];

//     for(const [param, value] of Object.entries(params)) {
//         if(PARAMS_ENDPOINTS_MAP[param]) {
//             for(const endpoint of PARAMS_ENDPOINTS_MAP[param]) {
//                 invalidations.push([endpoint, value]);
//             }
//         }
//     }

//     return invalidations;
// }

/** Command Endpoints which by invalidate other endpoints based on the invalidatedBy configuration */
export const INVALIDATIONS = mapInvalidations();
function mapInvalidations() {
    const invalidations: Record<string, string[]> = {};

    for (const [namespace, endpoints] of Object.entries(ENDPOINTS)) {
        for (const [endpointName, endpoint] of Object.entries(endpoints)) {
            const endpointDef = endpoint as PrimitiveEndpointDefinition;
            const endpointKey = `${namespace}/${endpointName}`;

            if (endpointDef.invalidatedBy) {
                for (const invalidatedBy of endpointDef.invalidatedBy) {
                    if (!invalidations[invalidatedBy]) {
                        invalidations[invalidatedBy] = [];
                    }
                    invalidations[invalidatedBy].push(endpointKey);
                }
            }

            if (endpointDef.invalidates) {
                for (const invalidates of endpointDef.invalidates) {
                    if (!invalidations[endpointKey]) {
                        invalidations[endpointKey] = [];
                    }
                    invalidations[endpointKey].push(invalidates);
                }
            }
        }
    }

    return invalidations;
}

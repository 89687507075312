import type { ErrorResponseType } from '../constants';

export interface ErrorResponse {
    code?: number;
    groupName?: string;
    message: string;
    memberName?: string;
}

/** The standard response type from the server for any sort of error, handled or otherwise */
export type ErrorsResponse<TError = ErrorResponse> = {
    type: ErrorResponseType;
    errors: TError[];
    fieldErrors?: { [field: string]: ErrorResponse; };
};

export function isErrorsResponse<TError = ErrorResponse>(arg: unknown): arg is ErrorsResponse<TError> {
    return !!(arg as ErrorsResponse<TError>).errors && !!(arg as ErrorsResponse<TError>).type;
}

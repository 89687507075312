import { useQueryClient } from 'vue-query';
import type { QueryEndpoint } from '../endpoints';

export function useQueryInvalidation() {
    const queryClient = useQueryClient();
    const invalidateQuery = async (queryKey: QueryEndpoint) => {
        await queryClient.invalidateQueries(queryKey);
    };

    return invalidateQuery;
}

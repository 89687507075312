type ConditionalArgs<TEvents extends Record<TEvent, unknown>, TEvent extends keyof TEvents> = TEvents[TEvent] extends
    undefined ? [eventName: TEvent]
    : [eventName: TEvent, payload: TEvents[TEvent]];

type AnyData = {
    [key: string]: unknown;
};

// Originally from: https://github.com/scottcorgan/tiny-emitter
// Augmented in Clawfoot projects to support TypeScript
// Re-engineered from recent learnings to use CustomEvent instead of Event to simplify implementation
export class TinyEmitter<TEvents extends Partial<AnyData> = AnyData> {
    private name: string;
    private loggingEnabled = true;
    private isPrivate: boolean; // Indicates this is private, part of a class instance, used internally by that class to emit externally
    private readonly eventBus: Comment; // Switched to using Comment as this greatly simplifies how this works

    constructor(name: string, isPrivate: boolean = false) {
        this.eventBus = new Comment(name);
        this.name = name;
        this.isPrivate = isPrivate;
    }

    on<TEvent extends keyof TEvents>(eventName: TEvent, handlerFn: (payload: TEvents[TEvent]) => unknown) {
        const nativeEventHandler = (event: Event) => {
            if (!this.isCustomEvent<TEvent>(event)) {
                throw new Error('Unsupported. Event is not a custom event');
            }

            handlerFn(event.detail);
        };

        this.eventBus.addEventListener(String(eventName), nativeEventHandler);
        return () => {
            this.eventBus.removeEventListener(String(eventName), nativeEventHandler);
        };
    }

    once<TEvent extends keyof TEvents>(
        eventName: TEvent,
        handlerFn: (payload: TEvents[TEvent]) => unknown,
        filter?: (payload: TEvents[TEvent]) => boolean,
    ) {
        const cleanup = this.on(eventName, (localPayload: TEvents[TEvent]) => {
            if (filter && !filter(localPayload)) return;

            handlerFn(localPayload);
            cleanup();
        });

        return cleanup;
    }

    emit<TEvent extends keyof TEvents>(...args: ConditionalArgs<TEvents, TEvent>) {
        const [eventName, payload] = args;

        const event = payload
            ? new CustomEvent<TEvents[TEvent]>(String(eventName), { detail: payload })
            : new CustomEvent(String(eventName));

        if (this.loggingEnabled) {
            if (this.isPrivate) {
                console.log(`%c ${this.name}: ${String(eventName)}`, 'color: #3a86ca', payload);
            } else {
                console.log(`%c == Emitting Event == "${String(eventName)}"`, 'color: #3a86ca', payload);
            }
        }

        this.eventBus.dispatchEvent(event);
    }

    dispose() {
        this.eventBus.remove();
    }

    private isCustomEvent<TEvent extends keyof TEvents>(event: Event): event is CustomEvent<TEvents[TEvent]> {
        return 'detail' in event;
    }
}

import type { EndpointDefinitions } from '@/features/api';
import type { EntitySearchRequest } from '@/features/querying';
import { API_BASE } from '@/shared';
import type { OrderRunAllowedStateChanges, OrderRunResponse } from './orderRunsApiResponseModels';

export type OrderRunsEndpointDefinitions = {
    GET_ORDER_RUN_ALLOWED_STATES: {
        request: never;
        response: OrderRunAllowedStateChanges;
        kind: 'query';
    };
    GET_RUN: {
        request: never;
        response: OrderRunResponse;
        kind: 'query';
    };
    LIST_RUNS: {
        request: EntitySearchRequest;
        response: OrderRunResponse[];
        kind: 'query';
    };
    PUT_ON_HOLD: {
        request: string;
        response: never;
        kind: 'command';
    };
    RESUME: {
        request: string;
        response: never;
        kind: 'command';
    };
};

export const ORDER_RUNS_ENDPOINTS = {
    GET_ORDER_RUN_ALLOWED_STATES: {
        path: `${API_BASE}/order-run/allowed-state-changes`,
        method: 'GET',
        kind: 'query',
    },
    GET_RUN: {
        path: `${API_BASE}/order-run/{runId}`,
        method: 'GET',
        kind: 'query',
    },
    LIST_RUNS: {
        path: `${API_BASE}/order-runs`,
        method: 'POST',
        kind: 'query',
    },
    PUT_ON_HOLD: {
        path: `${API_BASE}/order-run/{runId}/hold`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Run put on hold',
    },
    RESUME: {
        path: `${API_BASE}/order-run/{runId}/resume`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Run resumed',
    },
} as const satisfies EndpointDefinitions<OrderRunsEndpointDefinitions>;

import type { JwtToken } from '../types';
import type { Store } from './types';
import { JWT_KEY, REFRESH_TOKEN_KEY } from './types';

export class Local implements Store {
    /**
     * Store the token
     *
     * @param token
     */
    public store({ token, refreshToken }: JwtToken): void {
        localStorage.setItem(JWT_KEY, token);
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    }

    /**
     * Retrieve the token
     */
    public retrieve(): JwtToken | null {
        const jwt = localStorage.getItem(JWT_KEY);
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

        if (!jwt || !refreshToken) {
            return null;
        }

        return {
            token: jwt,
            refreshToken,
        };
    }

    /**
     * Forget the token
     */
    public forget(): void {
        localStorage.removeItem(JWT_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
    }
}

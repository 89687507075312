import type { EndpointDefinitions } from '@/features/api';
import type { EntitySearchRequest } from '@/features/querying';
import { API_BASE } from '@/shared';
import type { CreateCreativeRequest, EditCreativeRequest } from './creativeApiRequestModels';
import type {
    CreativeProcessingStatusResponse,
    NewCreativeResponse,
    SimpleCreativeResponse,
} from './creativeApiResponseModels';

export type CreativeEndpointDefinitions = {
    SEARCH_CREATIVES: {
        request: Partial<EntitySearchRequest>;
        response: SimpleCreativeResponse[];
        kind: 'query';
    };
    LIST_CREATIVES: {
        request: never;
        response: SimpleCreativeResponse[];
        kind: 'query';
    };
    GET_CREATIVE: {
        request: never;
        response: SimpleCreativeResponse;
        kind: 'query';
    };
    GET_DOWNLOAD_URL: {
        request: never;
        response: string;
        kind: 'query';
    };
    GET_DOWNLOAD_URLS: {
        request: string[];
        response: string[];
        kind: 'query';
    };
    GET_VIEW_URL: {
        request: never;
        response: string;
        kind: 'query';
    };
    GET_PROCESSING_STATUS: {
        request: never;
        response: CreativeProcessingStatusResponse;
        kind: 'query';
    };
    CREATE_CREATIVE: {
        request: CreateCreativeRequest;
        response: NewCreativeResponse;
        kind: 'command';
    };
    QUEUE_CREATIVE_PROCESSING: {
        request: never;
        response: never;
        kind: 'command';
    };
    EDIT_CREATIVE: {
        request: EditCreativeRequest;
        response: never;
        kind: 'command';
    };
    DELETE_CREATIVE: {
        request: never;
        response: never;
        kind: 'command';
    };
};

export const CREATIVE_ENDPOINTS = {
    LIST_CREATIVES: {
        path: `${API_BASE}/company/{companyId}/creatives`,
        method: 'GET',
        kind: 'query',
    },
    SEARCH_CREATIVES: {
        path: `${API_BASE}/creatives/search`,
        method: 'POST',
        kind: 'query',
    },
    GET_CREATIVE: {
        path: `${API_BASE}/creative/{creativeId}`,
        method: 'GET',
        kind: 'query',
    },
    GET_DOWNLOAD_URL: {
        path: `${API_BASE}/creative/{creativeId}/file-url/download`,
        method: 'GET',
        kind: 'query',
    },
    GET_DOWNLOAD_URLS: {
        path: `${API_BASE}/creatives/file-urls/download`,
        method: 'POST',
        kind: 'query',
    },
    GET_VIEW_URL: {
        path: `${API_BASE}/creative/{creativeId}/file-url`,
        method: 'GET',
        kind: 'query',
    },
    GET_PROCESSING_STATUS: {
        path: `${API_BASE}/creative/{creativeId}/processing-status`,
        method: 'GET',
        kind: 'query',
    },
    CREATE_CREATIVE: {
        path: `${API_BASE}/company/{companyId}/creatives`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Creative Initialized',
    },
    QUEUE_CREATIVE_PROCESSING: {
        path: `${API_BASE}/creative/{creativeId}/queue-processing`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Creative processing queued',
    },
    EDIT_CREATIVE: {
        path: `${API_BASE}/creative/{creativeId}`,
        method: 'PUT',
        kind: 'command',
        successMessage: 'Creative saved',
    },
    DELETE_CREATIVE: {
        path: `${API_BASE}/creative/{creativeId}`,
        method: 'DELETE',
        kind: 'command',
        successMessage: 'Creative deleted',
    },
} as const satisfies EndpointDefinitions<CreativeEndpointDefinitions>;

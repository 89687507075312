import { callApiWithoutAuth } from '@/features/api/utilities';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { DEFAULT_ROUTE } from '../config';
import { useAuthStore } from './auth.store';

export const useLoginStore = defineStore('loginStore', () => {
    const router = useRouter();
    const returnUrl = ref<string | null>(null);

    function setReturnUrl(url: string | null) {
        returnUrl.value = url;
    }

    function goToReturnUrl() {
        const url = returnUrl.value || DEFAULT_ROUTE;
        setReturnUrl(null);
        void router.push(url);
    }

    async function login(email: string, password: string) {
        const result = await callApiWithoutAuth('auth/LOGIN', {
            password,
            email,
        });

        if (result.success) {
            useAuthStore().setLogin(result.data);
            useLoginStore().goToReturnUrl();
            return result;
        }

        return result;
    }

    return {
        returnUrl,
        setReturnUrl,
        goToReturnUrl,
        login,
    };
});

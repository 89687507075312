import { callApiWithoutAuth } from '@/features/api/utilities';
import { defineStore } from 'pinia';

export const useAcceptInviteStore = defineStore('acceptInviteStore', () => {
    async function acceptInvite(
        password: string,
        userId: string,
        token: string,
    ) {
        const result = await callApiWithoutAuth('auth/ACCEPT_INVITE', {
            userId,
            token,
            password,
        });

        return result;
    }

    return {
        acceptInvite,
    };
});

// https://github.com/georgehanson/jwt-manager

import { TinyEmitter } from '@/shared/utilities';
import type { Store } from './stores';
import { Local } from './stores';
import type { Token } from './token';
import type { JwtToken } from './types';
import { decodeJwtAsToken } from './utility';

type JwtManagerEvents = {
    'token-set': JwtToken;
    'token-refreshed': JwtToken;
    'token-expiring': JwtToken;
    'token-expired': JwtToken;
    'token-removed': undefined;
};

export class JwtManager extends TinyEmitter<JwtManagerEvents> {
    /** The number of ms for each interval check*/
    private monitorInterval: number = 5000;
    private expirationThreshold: number;
    private store: Store = new Local();

    private cachedDecoded: Token | null = null;

    /**
     * @param expirationThreshold The number of seconds before the token expires to notify listeners
     */
    constructor(expirationThreshold: number = 15) {
        super('jwt-manager');

        this.expirationThreshold = expirationThreshold * 1000;
        this.startMonitor();
    }

    public get decodedToken(): Token | null {
        if (this.cachedDecoded) {
            return this.cachedDecoded;
        }

        const tokens = this.getToken();
        if (!tokens) {
            return null;
        }

        const decoded = decodeJwtAsToken(tokens);
        this.cachedDecoded = decoded;

        return decoded;
    }

    public setToken(tokens: JwtToken): void {
        this.store.store(tokens);
        this.cachedDecoded = null;
        this.emit('token-set', tokens);
    }

    public getToken(): JwtToken | null {
        return this.store.retrieve();
    }

    public forget(): void {
        this.store.forget();
        this.cachedDecoded = null;
        this.emit('token-removed');
    }

    /** Forget the old token and replace it with the new one */
    public refresh(token: JwtToken): void {
        this.store.forget();
        this.cachedDecoded = null;
        this.store.store(token);
        this.emit('token-refreshed', token);
    }

    /** Get the number of remaining ms until the token expires */
    public timeRemaining() {
        const token = this.decodedToken;

        if (token) {
            return (token.expiry - (Date.now() / 1000)) * 1000;
        }

        return 0;
    }

    public hasTokenExpired(): boolean {
        const msRemaining = this.timeRemaining();

        if (!msRemaining) {
            return false;
        }

        return msRemaining <= 0;
    }

    public isTokenExpiringSoon(): boolean {
        const msRemaining = this.timeRemaining();

        return msRemaining <= this.expirationThreshold && msRemaining > 0;
    }

    private startMonitor() {
        setInterval(() => {
            try {
                const token = this.getToken();
                if (!token) return;

                if (this.isTokenExpiringSoon()) {
                    this.emit('token-expiring', token);
                }

                if (this.hasTokenExpired()) {
                    this.emit('token-expired', token);
                }
            } catch (e) {
                console.error('Failed to handle token expiring', e);
            }
        }, this.monitorInterval);
    }

    // public useLocalStore(): void {
    //     this.store = new Local();
    //     this.decoder.useLocalStore();
    // }
}

import type { EndpointDefinitions } from '@/features/api';
import type { QueryEndpoint } from '@/features/api/endpoints';
import { API_BASE } from '@/shared';
import type { ExtractPrefix, ExtractSuffix } from '@/shared/types';
import type {
    DashboardBillingSetupStatusResponse,
    DashboardPendingInvoiceResponse,
} from './dashboardApiResponseModels';

export type DashboardEndpointDefinitions = {
    GET_PENDING_INVOICES: {
        request: never;
        response: DashboardPendingInvoiceResponse[];
        kind: 'query';
    };
    GET_TOTAL_SPEND_YTD: {
        request: never;
        response: number;
        kind: 'query';
    };
    GET_TOTAL_PLAYS_YTD: {
        request: never;
        response: number;
        kind: 'query';
    };
    GET_AVG_ORDER_COST: {
        request: never;
        response: number;
        kind: 'query';
    };
    GET_PLAYS_THIS_MONTH: {
        request: never;
        response: number;
        kind: 'query';
    };
    GET_BILLING_SETUP_STATUS: {
        request: never;
        response: DashboardBillingSetupStatusResponse;
        kind: 'query';
    };
};

export const DASHBOARD_ENDPOINTS = {
    GET_PENDING_INVOICES: {
        path: `${API_BASE}/dashboard/feature/pending-invoices`,
        method: 'GET',
        kind: 'query',
    },
    GET_TOTAL_SPEND_YTD: {
        path: `${API_BASE}/dashboard/feature/total-spend/ytd`,
        method: 'GET',
        kind: 'query',
    },
    GET_TOTAL_PLAYS_YTD: {
        path: `${API_BASE}/dashboard/feature/total-plays/ytd`,
        method: 'GET',
        kind: 'query',
    },
    GET_AVG_ORDER_COST: {
        path: `${API_BASE}/dashboard/feature/avg-order-cost`,
        method: 'GET',
        kind: 'query',
    },
    GET_PLAYS_THIS_MONTH: {
        path: `${API_BASE}/dashboard/feature/total-plays/this-month`,
        method: 'GET',
        kind: 'query',
    },
    GET_BILLING_SETUP_STATUS: {
        path: `${API_BASE}/dashboard/feature/billing-setup`,
        method: 'GET',
        kind: 'query',
    },
} as const satisfies EndpointDefinitions<DashboardEndpointDefinitions>;

type EndpointSingleValueFilter<T extends Record<string, { response: unknown; }>> = {
    [K in keyof T]: T[K]['response'] extends number | string ? K : never;
}[keyof T];

type NumberOrStringEndpoints = EndpointSingleValueFilter<DashboardEndpointDefinitions>;

export type DashboardQueryEndpoint = ExtractPrefix<QueryEndpoint, 'dashboard/'>;
export type DashboardSingleValueQueryEndpoints = ExtractSuffix<DashboardQueryEndpoint, NumberOrStringEndpoints>;

import type { EndpointDefinitions } from '@/features/api';
import { API_BASE } from '@/shared/constants';
import type {
    CompanySearchRequest,
    CreateCompanyRequest,
    EditCompanyRequest,
    SetupCompanyRequest,
} from './companyApiRequestModels';
import type { CompanyResponse, CompanySearchResponse } from './companyApiResponseModels';

export type CompanyEndpointDefinitions = {
    GET_COMPANY: {
        request: never;
        response: CompanyResponse;
        kind: 'query';
    };
    GET_PARENT: {
        request: never;
        response: CompanyResponse;
        kind: 'query';
    };
    GET_COMPANIES: {
        request: never;
        response: CompanyResponse[];
        kind: 'query';
    };
    SETUP_MY_COMPANY: {
        request: SetupCompanyRequest;
        response: CompanyResponse;
        kind: 'command';
    };
    SETUP_COMPANY: {
        request: SetupCompanyRequest;
        response: CompanyResponse;
        kind: 'command';
    };
    EDIT_COMPANY: {
        request: EditCompanyRequest;
        response: CompanyResponse;
        kind: 'command';
    };
    CREATE_NORMAL_COMPANY: {
        request: CreateCompanyRequest;
        response: CompanyResponse;
        kind: 'command';
    };
    CREATE_ORGANIZATION: {
        request: CreateCompanyRequest;
        response: CompanyResponse;
        kind: 'command';
    };
    CREATE_NETWORK: {
        request: CreateCompanyRequest;
        response: CompanyResponse;
        kind: 'command';
    };
    CREATE_SUB_COMPANY: {
        request: CreateCompanyRequest;
        response: CompanyResponse;
        kind: 'command';
    };
    LIVE_SEARCH: {
        request: CompanySearchRequest;
        response: CompanySearchResponse[];
        kind: 'query';
    };
};

export const COMPANY_ENDPOINTS = {
    GET_COMPANY: {
        path: `${API_BASE}/company/{companyId}`,
        method: 'GET',
        kind: 'query',
    },
    GET_PARENT: {
        path: `${API_BASE}/company/{companyId}/parent`,
        method: 'GET',
        kind: 'query',
    },
    GET_COMPANIES: {
        path: `${API_BASE}/companies`,
        method: 'GET',
        kind: 'query',
    },
    SETUP_MY_COMPANY: {
        path: `${API_BASE}/my-company/setup`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Company setup successfully',
    },
    SETUP_COMPANY: {
        path: `${API_BASE}/company/{companyId}/setup`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Company setup successfully',
    },
    EDIT_COMPANY: {
        path: `${API_BASE}/company/{companyId}`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Company updated successfully',
    },
    CREATE_NORMAL_COMPANY: {
        path: `${API_BASE}/{parentCompanyId}/company`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Company created',
    },
    CREATE_ORGANIZATION: {
        path: `${API_BASE}/{parentCompanyId}/company/organization`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Organization created',
    },
    CREATE_NETWORK: {
        path: `${API_BASE}/{parentCompanyId}/company/network`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Network created',
    },
    CREATE_SUB_COMPANY: {
        path: `${API_BASE}/{parentCompanyId}/company/sub`,
        method: 'POST',
        kind: 'command',
        successMessage: 'Client created',
    },
    LIVE_SEARCH: {
        path: `${API_BASE}/companies/search?query={query}`,
        method: 'POST',
        kind: 'query',
    },
} as const satisfies EndpointDefinitions<CompanyEndpointDefinitions>;

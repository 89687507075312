import { useApi } from '@/features/api/hooks/useApi';
import type { Ref } from 'vue';
import { computed } from 'vue';
import type { RoleResponse } from '../api/authApiResponseModels';

export function useAppRoles(enabled?: Ref<boolean | undefined>) {
    const isEnabled = computed(() => {
        return enabled?.value ?? true;
    });

    const { isLoading, data } = useApi('auth/GET_ROLES', (endpoint, fetcher) => {
        return fetcher(endpoint);
    }, { staleTime: 60000, cacheTime: 60000, enabled: isEnabled });

    const roles = computed(() => {
        return data.value ? data.value : [] as RoleResponse[];
    });

    /** Preformatted roles for select components */
    const selectRoles = computed(() => {
        return roles.value.map((x) => ({ title: x.friendlyName, value: x.name }));
    });

    return {
        isLoading,
        roles,
        selectRoles,
    };
}

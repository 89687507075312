import { useMutation, useQueryClient } from 'vue-query';
import type { UseMutationOptions } from 'vue-query';
import { toast } from 'vuetify-sonner';
import type { CommandEndpoint, EndpointPrefix, EndpointResponse, QueryEndpoint } from '../endpoints';
import { getEndpointsByPrefix, isQueryKeyEndpointPrefix } from '../endpoints';
import type { ErrorsResponse } from '../models/errorsResponse';
import { callApiUnsafe, callApiWithoutAuthUnsafe, getEndpointDefinition } from '../utilities';
import type { ApiCallRequest } from '../utilities';
import { getMessagesFromError } from './useApiResponseErrors';

export function useCommand<
    TEndpoint extends CommandEndpoint,
    TError = ErrorsResponse,
    TVariables extends ApiCallRequest<TEndpoint> = ApiCallRequest<TEndpoint>,
    TData = EndpointResponse[TEndpoint],
    TContext = unknown,
>(
    endpoint: TEndpoint,
    options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'> & {
        skipAuth?: boolean;

        /** Invalidate queries that relate to this command/mutation. */
        invalidates?: ((QueryEndpoint | EndpointPrefix) | [QueryEndpoint, ...unknown[]])[];
        toastErrors?: boolean;
    },
    commandFn?: (
        endpoint: TEndpoint,
        variables: TVariables,
        apiFetcher: typeof callApiUnsafe<TEndpoint>,
    ) => Promise<TData>,
) {
    const { method, successMessage, invalidates: configInvalidates } = getEndpointDefinition(endpoint);
    const queryClient = useQueryClient();

    const defaultOptions = { throwOnError: false, useErrorBoundary: false };
    const allOptions = options
        ? { ...defaultOptions, ...options }
        : defaultOptions as UseMutationOptions<TData, TError, TVariables, TContext>;

    const apiCaller = options?.skipAuth ? callApiWithoutAuthUnsafe : callApiUnsafe;

    const onSuccess = (data: TData, variables: TVariables, context: TContext | undefined) => {
        if (successMessage) {
            toast.success(successMessage);
        }

        // Ensure that the onSuccess callback is called if it exists
        if (options?.onSuccess && typeof options.onSuccess === 'function') {
            void options.onSuccess(data, variables, context);
        }

        const invalidations = [...options?.invalidates ?? [], ...configInvalidates ?? []];

        // Dynamically invalidate queries either directly by the query key or by prefix
        if (invalidations) {
            invalidations.forEach(queryKey => {
                // If the queryKey is a prefix, invalidate all queries that start with that prefix
                if (isQueryKeyEndpointPrefix(queryKey)) {
                    const endpoints = getEndpointsByPrefix(queryKey);
                    endpoints.forEach(endpoint => {
                        void queryClient.invalidateQueries({ queryKey: endpoint });
                    });
                } else {
                    void queryClient.invalidateQueries({ queryKey });
                }
            });
        }
    };

    const onError = (error: TError, variables: TVariables, context: TContext | undefined) => {
        if (options?.toastErrors === undefined || options.toastErrors === true) {
            toast.error(getMessagesFromError(error as never)[0]);
        }

        // Ensure that the onError callback is called if it exists
        if (options?.onError && typeof options.onError === 'function') {
            void options.onError(error, variables, context);
        }
    };

    // Provide a sane default for the commandFn
    // This is a bunch of extra complexity & fragility, but it saves a LOT of boilerplate
    //  by not forcing the user to write a boilerplate commandFn for every command
    const defaultCommandFunc = commandFn
        ? commandFn
        : (endpoint: TEndpoint, variables: TVariables, fetcher: typeof callApiUnsafe) => {
            const args: unknown[] = [endpoint];
            if (method == 'GET') {
                // Second arg is request if present, otherwise it must be params (or undefined)
                args.push(variables.request ?? variables.params);
                args.push(variables.params);
            } else {
                args.push(variables.request);
                args.push(variables.params);
            }

            // @ts-expect-error "Source provides no match for variadic element at position 0 in target" this is fine
            return fetcher(...args);
        };

    return useMutation({
        ...allOptions,
        mutationFn: async (variables: TVariables) => {
            return await defaultCommandFunc(endpoint, variables, apiCaller) as Promise<TData>;
        },
        onSuccess,
        onError,
    });
}

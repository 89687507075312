import type { Ref } from 'vue';
import { computed , toValue } from 'vue';

import { useAppRoles } from './useAppRoles';

export function usePermsForRole(name: string | Ref<string>) {
    const { roles, isLoading } = useAppRoles();

    const rolePerms = computed(() => {
        const role = roles.value?.find((x) => x.name === toValue(name));

        return role ? role.claims : [];
    });

    return {
        isLoading,
        rolePerms,
    };
}

export function usePermsForRoles(useRoles: Ref<string[] | undefined> | undefined) {
    const { roles, isLoading } = useAppRoles();

    const rolePerms = computed(() => {
        if (!useRoles?.value) {
            return {} as Record<string, string[]>;
        }
        // map of claims and the role(s) it was assigned from
        const roleClaims: Record<string, string[]> = {};

        for (const roleName of useRoles.value) {
            const role = roles.value?.find((x) => x.name === roleName);

            if (role) {
                for (const claim of role.claims) {
                    if (!roleClaims[claim]) {
                        roleClaims[claim] = [];
                    }
                    roleClaims[claim].push(roleName);
                }
            }
        }

        return roleClaims;
    });

    return {
        isLoading,
        rolePerms,
    };
}

import { useAuthStore } from '../stores';

export function useCapabilities() {
    const { userAbility } = useAuthStore();

    return {
        can: userAbility.can,
        canExclusive: userAbility.canExclusive,
        createdByUser: userAbility.createdByUser,
        hasAtLeastRole: userAbility.hasAtLeastRole,
    };
}

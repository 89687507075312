import type { EndpointDefinitions } from '@/features/api';
import { API_BASE } from '@/shared/constants';
import type { IndustrySectorResponse } from './industryApiResponseModels';

export type IndustryEndpointDefinitions = {
    GET_INDUSTRIES: {
        request: never;
        response: IndustrySectorResponse[];
        kind: 'query';
    };
};

export const INDUSTRY_ENDPOINTS = {
    GET_INDUSTRIES: {
        path: `${API_BASE}/industries`,
        method: 'GET',
        kind: 'query',
    },
} as const satisfies EndpointDefinitions<IndustryEndpointDefinitions>;

/* eslint-disable @typescript-eslint/no-unsafe-argument */
function getString(key: string, envValue: string, defaultValue?: string): string {
    if (defaultValue !== undefined && !envValue) {
        return defaultValue;
    }

    if (!defaultValue && !envValue) {
        throw new Error(`Missing environment variable: ${key}`);
    }
    return envValue;
}

function getAppVersion() {
    if (ENVIRONMENT === 'dev') {
        return `${__APP_VERSION__}-indev`;
    }

    return __APP_VERSION__;
}
import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
export const ENVIRONMENT = getString('VITE_ENVIRONMENT', import.meta.env.VITE_ENVIRONMENT);
export const SERVER_BASE_URL = getString(
    'VITE_SERVER_BASE_URL',
    import.meta.env.VITE_SERVER_BASE_URL,
    window.location.origin,
);
export const SERVER_API_PATH = getString('VITE_SERVER_API_PATH', import.meta.env.VITE_SERVER_API_PATH, '');
export const APP_VERSION = getAppVersion();
export const STRIPE_PUBLISHABLE_KEY = getString(
    'VITE_STRIPE_PUBLISHABLE_KEY',
    import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
);

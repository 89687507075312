import { callApiWithoutAuth } from '@/features/api/utilities';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useVerifyEmailStore = defineStore('verifyEmailStore', () => {
    const verified = ref(false);
    async function verifyEmail(userId: string, code: string) {
        const result = await callApiWithoutAuth('auth/VERIFY_EMAIL', {
            userId,
            code,
        });

        if (result.success) {
            verified.value = true;
        }

        return result;
    }

    return {
        verifyEmail,
    };
});

export const SCOPES = new Set(['any', 'own', 'all', 'self', 'sub', 'root']);

/** This must be updated from the API output periodically when backend capabilities change */
export const CAPABILITIES = [
    'campaign:create:any',
    'campaign:create:own',
    'campaign:create:sub',
    'campaign:manage:any',
    'campaign:manage:own',
    'campaign:manage:sub',
    'category:manage:any',
    'company:create:any',
    'company:create:sub',
    'company:manage:any',
    'company:manage:own',
    'company:manage:sub',
    'screen:manage:any',
    'user:manage:self',
    'user:manage:own',
    'user:manage:root',
    'user:manage:any',
    'user:admin:manage:own',
    'user:admin:manage:root',
    'user:admin:manage:any',
    'user:company:manage:any',
    'user:reset-password:create:any',
    'user:reset-password:create:own',
    'user:invite:create:own',
    'user:invite:create:any',
    'user:invite:create:root',
    'billing:manage:any',
    'billing:manage:own',
] as const;
export type Capability = typeof CAPABILITIES[number];

import { callApiWithoutAuth } from '@/features/api/utilities';
import type { SimpleResult } from '@/shared/types';
import { sleep } from '@/shared/utilities';
import { defineStore } from 'pinia';

export const useResetPasswordStore = defineStore('resetPasswordStore', () => {
    async function resetPassword(
        password: string,
        userId: string,
        token: string,
    ) {
        const result = await callApiWithoutAuth('auth/RESET_PASSWORD', {
            userId,
            token,
            newPassword: password,
        });

        return result;
    }

    async function validateToken(userId: string, token: string): Promise<SimpleResult> {
        if (!userId || !token) {
            return {
                success: false,
                message: 'Invalid token',
            };
        }

        await sleep(1000);

        return {
            success: true,
        };
    }

    return {
        resetPassword,
        validateToken,
    };
});

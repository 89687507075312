import type { JwtTokenStructure } from '../models';
import { Token } from './token';
import type { JwtToken } from './types';

export function decodeJwt<T>(tokens: JwtToken): T {
    if (tokens) {
        const sectioned = tokens.token.split('.')[1];
        const replaced = sectioned.replace('-', '+').replace('_', '/');
        const decoded = JSON.parse(window.atob(replaced)) as T;

        return decoded;
    }

    throw new TypeError('No token has been set');
}

export function decodeJwtAsToken(tokens: JwtToken): Token {
    return new Token(decodeJwt<JwtTokenStructure>(tokens));
}

import { hsl } from 'color-convert';

export function stringToHex(str: string, saturation: number, lightness: number) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // eslint-disable-next-line no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    return hsl.hex([hash % 360, saturation, lightness]);
}

export function colorizeString(str: string) {
    return `#${stringToHex(str, 60, 65)}`;
}

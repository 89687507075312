import { useApi } from '@/features/api/hooks';
import { computed } from 'vue';

export function useAppPerms() {
    const { isLoading, data } = useApi('auth/GET_PERMS', (endpoint, fetcher) => {
        return fetcher(endpoint);
    }, { staleTime: 60000 });

    const perms = computed(() => {
        return data.value ? data.value : [];
    });

    return {
        isLoading,
        perms,
    };
}

import type { EndpointDefinitions } from '@/features/api';
import { API_BASE } from '@/shared/constants';
import type { BudgetDetailsResponse } from './budgetApiResponseModels';

export type BudgetEndpointDefinitions = {
    GET_COMPANY_BUDGET: {
        request: never;
        response: BudgetDetailsResponse;
        kind: 'query';
    };
    GET_COMPANY_CAMPAIGN_BUDGET: {
        request: never;
        response: BudgetDetailsResponse;
        kind: 'query';
    };
    GET_CAMPAIGN_BUDGET: {
        request: never;
        response: BudgetDetailsResponse;
        kind: 'query';
    };
};

export const BUDGET_ENDPOINTS = {
    GET_COMPANY_BUDGET: {
        path: `${API_BASE}/company/{companyId}/budget`,
        method: 'GET',
        kind: 'query',
        invalidatedBy: [
            'campaigns/EDIT_CAMPAIGN',
            'orders/EDIT_ORDER',
            'campaigns/CREATE_ORDER',
            'company/EDIT_COMPANY',
        ],
    },
    // The combined campaign/company budget, constrained to whichever is less
    GET_COMPANY_CAMPAIGN_BUDGET: {
        path: `${API_BASE}/company/{companyId}/campaign/{campaignId}/budget`,
        method: 'GET',
        kind: 'query',
        invalidatedBy: [
            'campaigns/EDIT_CAMPAIGN',
            'orders/EDIT_ORDER',
            'campaigns/CREATE_ORDER',
            'company/EDIT_COMPANY',
        ],
    },
    GET_CAMPAIGN_BUDGET: {
        path: `${API_BASE}/campaign/{campaignId}/budget`,
        method: 'GET',
        kind: 'query',
        invalidatedBy: ['campaigns/EDIT_CAMPAIGN', 'orders/EDIT_ORDER', 'campaigns/CREATE_ORDER'],
    },
} as const satisfies EndpointDefinitions<BudgetEndpointDefinitions>;

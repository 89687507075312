import type { Ref } from 'vue';

export const PASSWORD_FORM_RULES = [
    ...truthyRule('Password is required'),
    (v: string) => v.length > 10 || 'Password must be at least 10 characters',
    (v: string) =>
        /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(v)
        || 'Password must contain at least one uppercase letter, one lowercase letter, and one number',
];

export const EMAIL_FORM_RULES = [
    ...truthyRule('E-mail is required'),
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
];

export function truthyRule<TVal = string>(message: string) {
    return [
        (v: TVal) => !!v || message,
    ];
}

export function confirmPasswordRule(password: Ref<string>) {
    return [
        ...PASSWORD_FORM_RULES,
        (v: string) => (v && v === password.value) || 'Passwords must match',
    ];
}
